import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { GetItemBatchesFilter, ItemBatch, itemBatchSliceName, ResponseItemBatch } from "../dataTypes";
import { itemBatchAdapter } from "../itemBatchAdapter";

const getItemBatches = async (dispatch: AppDispatch, { filter }: { filter: Readonly<GetItemBatchesFilter> }) => {
  const url = api.itemBatch.getItemBatches();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  itemBatches: PaginationResponse<ResponseItemBatch>;
}

export const fetchItemBatches = createAsyncThunk<
  Response,
  { filter: Readonly<GetItemBatchesFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(itemBatchSliceName + "/fetchItemBatches", getItemBatches);

export const addFetchItemBatchesReducers = (
  builder: EntityActionReducerBuilderInterface<ItemBatch, AdditionalPaginationState>,
) => {
  builder.addCase(fetchItemBatches.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchItemBatches.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const itemBatches = payload.itemBatches.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        itemBatchAdapter.setAll(state, itemBatches);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        itemBatchAdapter.upsertMany(state, itemBatches);
        break;
    }

    state.count = payload.itemBatches.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchItemBatches.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
