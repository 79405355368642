import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { fetchFiles } from "../../item/reducers/fetchFiles";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { File } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addFetchFilesForItemReducers = (builder: EntityActionReducerBuilderInterface<File>) => {
  builder.addCase(fetchFiles.fulfilled, (state, action) => {
    const { payload } = action;

    const files = payload.files.page.map(mapFulfilledResponseObject);

    fileAdapter.upsertMany(state, files);
  });
};
