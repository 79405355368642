import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { addFiles } from "../../item/reducers/addFiles";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { File } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addAddFilesToItemReducers = (builder: EntityActionReducerBuilderInterface<File>) => {
  builder.addCase(addFiles.fulfilled, (state, action) => {
    const { payload } = action;

    const files = payload.files.map(mapFulfilledResponseObject);

    fileAdapter.upsertMany(state, files);
  });
};
