import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { GetItemsFilter, Item, itemSliceName, ResponseItem } from "../dataTypes";
import { itemAdapter } from "../itemAdapter";
import mapFulfilledItemResponse from "../utils/mapFulfilledItemResponse";

const getItems = async (dispatch: AppDispatch, { filter }: { filter: Readonly<GetItemsFilter> }) => {
  const url = api.item.getItems();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  items: PaginationResponse<ResponseItem>;
}

export const fetchItems = createAsyncThunk<
  Response,
  { filter: Readonly<GetItemsFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(itemSliceName + "/fetchItems", getItems);

export const addFetchItemsReducers = (
  builder: EntityActionReducerBuilderInterface<Item, AdditionalPaginationState>,
) => {
  builder.addCase(fetchItems.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchItems.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const items = payload.items.page.map(item =>
      mapFulfilledItemResponse({
        responseItem: item,
        currentItem: state.entities[item.id],
      }),
    );

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        itemAdapter.setAll(state, items);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        itemAdapter.upsertMany(state, items);
        break;
    }

    state.count = payload.items.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchItems.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
