import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { fileSliceName, ResponseFile } from "../../file/dataTypes";

import getFormData from "../../../utils/getFormData";
import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { Item } from "../dataTypes";

async function doAddFiles(dispatch: AppDispatch, { itemId, files }: { itemId: string; files: Blob[] }) {
  const request = new Request(api.item.addFiles(itemId), Method.POST);

  return await sendRequest(request, getFormData({ files }), await defaultRestOptions({ dispatch }));
}

interface Response {
  files: ResponseFile[];
}

export const addFiles = createAsyncThunk<Response, { itemId: string; files: Blob[] }>(
  fileSliceName + "/addFiles",
  doAddFiles,
);

export const addFilesReducers = (builder: EntityActionReducerBuilderInterface<Item>) => {
  builder.addCase(addFiles.pending, (state, action) => {
    const itemId = action.meta.arg.itemId;
    const item = state.entities[itemId];

    if (!item) return;

    if (!item.files) {
      item.files = { ids: [], count: 0 };
    }
  });
  builder.addCase(addFiles.fulfilled, (state, action) => {
    const { payload } = action;
    const itemId = action.meta.arg.itemId;
    const item = state.entities[itemId];

    if (!item || !item.files) return;

    item.files.ids.push(...payload.files.map(file => file.id));
    item.files.count += payload.files.length;
  });
};
