import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject, PaginationReferenceIds } from "../dataTypes";
import { FILE_FIELDS } from "../file/dataTypes";
import { ClientPaginatedRequestFilter, PaginationFilterOptions } from "../utils/pagination";

export const itemSliceName = "item";

export interface ResponseItem extends IdObject {
  goodsOwnerId: string;
  itemNumber: string;
  clientItemNumber: string;
  description: string;
  batchId: string;
  containerId: string;
  balance: number;
  reservedQuantity: number;
  availableQuantity: number;
  expirationDate: number;
  status: string;
  isAvailable: boolean;
  blockInformation: string;
  itemType: string;
  itemGroup: string;
  unit: string;
  unitNetWeight: number;
  unitVolume: number;
  grossHeight: number;
  grossWidth: number;
  grossDepth: number;
  storage1Balance: number;
  storage2Balance: number;
  storage3Balance: number;
  storage4Balance: number;
  storage5Balance: number;
  useSerialNumber: boolean;
  batchNumberManagement: boolean;
}

export interface GetItemsFilter extends ClientPaginatedRequestFilter {}

export interface Item extends ResponseItem, AdditionalRequestState {
  files?: PaginationReferenceIds;
}

export const ITEM_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponseItem, "id" | "fetchedTimestamp" | "files">>>]: CamelCase<
    Lowercase<key>
  >;
} = {
  GOODS_OWNER_ID: "goodsOwnerId",
  ITEM_NUMBER: "itemNumber",
  CLIENT_ITEM_NUMBER: "clientItemNumber",
  DESCRIPTION: "description",
  BATCH_ID: "batchId",
  CONTAINER_ID: "containerId",
  BALANCE: "balance",
  RESERVED_QUANTITY: "reservedQuantity",
  AVAILABLE_QUANTITY: "availableQuantity",
  EXPIRATION_DATE: "expirationDate",
  STATUS: "status",
  IS_AVAILABLE: "isAvailable",
  BLOCK_INFORMATION: "blockInformation",
  ITEM_TYPE: "itemType",
  ITEM_GROUP: "itemGroup",
  UNIT: "unit",
  UNIT_NET_WEIGHT: "unitNetWeight",
  UNIT_VOLUME: "unitVolume",
  GROSS_HEIGHT: "grossHeight",
  GROSS_WIDTH: "grossWidth",
  GROSS_DEPTH: "grossDepth",
  STORAGE1_BALANCE: "storage1Balance",
  STORAGE2_BALANCE: "storage2Balance",
  STORAGE3_BALANCE: "storage3Balance",
  STORAGE4_BALANCE: "storage4Balance",
  STORAGE5_BALANCE: "storage5Balance",
  USE_SERIAL_NUMBER: "useSerialNumber",
  BATCH_NUMBER_MANAGEMENT: "batchNumberManagement",
};

export interface GetItemFilesFilter extends PaginationFilterOptions {}

export const GET_ITEM_FILES = {
  ...FILE_FIELDS,
  GOODS_OWNER_ID: "goodsOwnerId",
};
