import { Box, BoxProps, Typography } from "@mui/material";
import { MutableOnlyArray, ValueOf } from "../../../utils/dataTypes";

export const VARIANTS = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
} as const;

type SXProps = NonNullable<BoxProps["sx"]>;

export interface LabelProps extends BoxProps {
  label: string;
  value?: string | number;
  variant?: ValueOf<typeof VARIANTS>;
}

const Label = ({ label, value, variant, sx: sxProp }: LabelProps) => {
  const sx: MutableOnlyArray<SXProps> = [
    { display: "flex", flexDirection: variant == VARIANTS.HORIZONTAL ? "row" : "column" },
  ];

  if (Array.isArray(sxProp)) sx.push(...sxProp);
  else if (sxProp) sx.push(sxProp);

  return (
    <Box sx={sx}>
      <Typography variant="body1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" sx={{ minHeight: "2.1rem" }}>
        {value}
      </Typography>
    </Box>
  );
};

export default Label;
