import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton } from "@mui/material";

export type PromptWrapperProps<TRowItem> = {
  PromptBody: React.ComponentType<{ onSelect: (row: TRowItem) => void }>;
  onSelectRow: (value: TRowItem) => void;
  closePrompt: () => void;
  title: string;
};

const PromptWrapper = <TRowItem,>({ PromptBody, closePrompt, onSelectRow, title }: PromptWrapperProps<TRowItem>) => {
  return (
    <>
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        onClick={closePrompt}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box padding={"0.8rem"}>
        <PromptBody
          onSelect={item => {
            closePrompt();
            onSelectRow(item);
          }}
        />
      </Box>
    </>
  );
};

export default PromptWrapper;
