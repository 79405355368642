const pages = Object.freeze({
  HOME: "/",
  EMBED_ADMINISTRATE_USERS: {
    NAME: "ADMINISTRATE_USERS",
    PATH: "/embed-administrate-users",
    COMFLOW_TASK_ID: "UsersControlTower",
  },

  //COMFLOW FUNCTIONS: INBOUND
  EMBED_INBOUND_BOOKING_UPDATE: {
    NAME: "BOOKING_UPDATE",
    PATH: "/embed-booking-update",
    ISAK_ROUTE_ID: "SCMINB001",
    COMFLOW_TASK_ID: "Isak/SCMINB001",
  },
  EMBED_INBOUND_BOOKING_SHOW: {
    NAME: "BOOKING_SHOW",
    PATH: "/embed-booking-show",
    ISAK_ROUTE_ID: "SCMINB001T",
    COMFLOW_TASK_ID: "Isak/SCMINB001T",
  },
  EMBED_INBOUND_INBOUND_SHIPMENTS: {
    NAME: "INBOUND_SHIPMENTS",
    PATH: "/embed-inbound-shipments",
    ISAK_ROUTE_ID: "SCMINB020",
    COMFLOW_TASK_ID: "Isak/SCMINB020",
  },
  EMBED_INBOUND_CONSIGNMENT_LIST: {
    NAME: "CONSIGNMENT_LIST",
    PATH: "/embed-consignment-list",
    COMFLOW_TASK_ID: "Inbound/ConsignmentList",
  },
  EMBED_INBOUND_RESERVATIONS_LIST: {
    NAME: "RESERVATIONS_LIST",
    PATH: "/embed-reservations-list",
    COMFLOW_TASK_ID: "Inbound/ReservationList",
  },
  EMBED_INBOUND_UNLOADING_REPORTS_SHOW: {
    NAME: "UNLOADING_REPORTS_SHOW",
    PATH: "/embed-unloading-reports-show",
    COMFLOW_TASK_ID: "Unloading/UnloadingReportShow",
  },
  EMBED_INBOUND_PLANNING_LIST: {
    NAME: "PLANNING_LIST",
    PATH: "/embed-planning-list",
    COMFLOW_TASK_ID: "Inbound/PlanningList",
  },
  EMBED_INBOUND_PLANNING_LIST_EXTERNAL: {
    NAME: "PLANNING_LIST_EXTERNAL",
    PATH: "/embed-planning-list-external",
    COMFLOW_TASK_ID: "Inbound/PlanningListExternal",
  },
  EMBED_INBOUND_DEVIATIONS: {
    NAME: "DEVIATIONS",
    PATH: "/embed-deviations",
    COMFLOW_TASK_ID: "Deviation/CustomerDeviation",
  },
  EMBED_INBOUND_DEVIATIONS_INTERNAL: {
    NAME: "DEVIATIONS_INTERNAL",
    PATH: "/embed-deviation-internal",
    COMFLOW_TASK_ID: "Deviation/DeviationProcess",
  },
  EMBED_INBOUND_NEW_ARRIVAL_REGISTRATION: {
    NAME: "NEW_ARRIVAL_REGISTRATION",
    PATH: "/embed-new-arrival-registration",
    COMFLOW_TASK_ID: "ArrivalRegistration",
  },
  EMBED_ONGOING_ARRIVAL_REGISTRATIONS: {
    NAME: "ARRIVAL_REGISTRATIONS",
    PATH: "/embed-ongoing-arrival-registrations",
    COMFLOW_TASK_ID: "OngoingArrivalRegistrations",
  },
  EMBED_INBOUND_ORDER_SHORTAGE_ITEMS_IMPORT: {
    NAME: "INBOUND_ORDER_SHORTAGE_ITEMS",
    PATH: "/embed-back-order-items-import",
    COMFLOW_TASK_ID: "BackOrderItemImport",
  },
  EMBED_MONITOR_ORDER_ITEMS: {
    NAME: "MONITOR_ORDER_ITEMS",
    PATH: "/embed-monitor-order-items",
    COMFLOW_TASK_ID: "Monitor/MonitorMessages",
  },
  EMBED_PURCHASE_ORDER_REGISTER_NEW: {
    NAME: "PURCHASE_ORDER_REGISTER_NEW",
    PATH: "/embed-purchase-order-register-new",
    COMFLOW_TASK_ID: "Order/PurchaseOrderRegister",
  },
  EMBED_PURCHASE_ORDERS_PRELIMINARY: {
    NAME: "PURCHASE_ORDERS_PRELIMINARY",
    PATH: "/embed-purchase-order-preliminary",
    COMFLOW_TASK_ID: "Order/PurchaseOrderPreliminary",
  },
  //COMFLOW FUNCTIONS: WAREHOUSE
  EMBED_WAREHOUSE_STADIUM_PRICE_LABELING: {
    NAME: "STADIUM_PRICE_LABELING",
    PATH: "/embed-stadium-price-labeling",
    COMFLOW_TASK_ID: "StadiumLabe/Pricelabeling",
  },
  EMBED_WAREHOUSE_ITEM_REGISTER_NEW: {
    NAME: "ITEM_REGISTER_NEW",
    PATH: "/embed-items-register-new",
    COMFLOW_TASK_ID: "Item/ItemRegister",
  },
  EMBED_WAREHOUSE_ITEMS_PRELIMINARY: {
    NAME: "ITEMS_PRELIMINARY",
    PATH: "/embed-items-preliminary",
    COMFLOW_TASK_ID: "Item/ItemPreliminary",
  },
  EMBED_WAREHOUSE_RETURN_HANDLING_LOAD_CARRIER: {
    NAME: "RETURN_HANDLING_LOAD_CARRIER",
    PATH: "/embed-return-handling-item-carrier",
    COMFLOW_TASK_ID: "ReturnHandling/ReturnHandlingLoadCarrier",
  },
  //COMFLOW FUNCTIONS: OUTBOUND
  EMBED_OUTBOUND_DELIVERY_ORDERS: {
    NAME: "DELIVERY_ORDERS",
    PATH: "/embed-delivery-order",
    COMFLOW_TASK_ID: "DeliveryOrder/DeliveryOrder",
  },
  EMBED_OUTBOUND_DELIVERY_ORDERS_ARCHIVE: {
    NAME: "DELIVERY_ORDERS_ARCHIVE",
    PATH: "/embed-delivery-order-archive",
    COMFLOW_TASK_ID: "DeliveryOrder/DeliveryOrderArchived",
  },
  EMBED_OUTBOUND_ITEM: {
    NAME: "ITEMS",
    PATH: "/embed-items",
    COMFLOW_TASK_ID: "DeliveryOrder/Item",
  },
  EMBED_OUTBOUND_DELIVERY_CREATE_RETURNS: {
    NAME: "DELIVERY_CREATE_RETURNS",
    PATH: "/embed-delivery-create-returns",
    COMFLOW_TASK_ID: "CreateReturn",
  },
  EMBED_OUTBOUND_DELIVERY_LIST_RETURNS: {
    NAME: "DELIVERY_LIST_RETURNS",
    PATH: "/embed-delivery-list-returns",
    COMFLOW_TASK_ID: "Returns",
  },
  EMBED_OUTBOUND_DELIVERY_LIST_INTERNAL: {
    NAME: "DELIVERY_RETURNS_INTERNAL",
    PATH: "/embed-delivery-returns-internal",
    COMFLOW_TASK_ID: "ReturnsInternal",
  },
  EMBED_OUTBOUND_IMPORT_ORDER_BH: {
    NAME: "IMPORT_ORDER_BH",
    PATH: "/embed-import-order-bh",
    COMFLOW_TASK_ID: "XLNT/ImportOrderBH",
  },
  EMBED_OUTBOUND_IMPORT_ORDER_BH_UPDATE: {
    NAME: "IMPORT_ORDER_BH_UPDATE",
    PATH: "/embed-import-order-bh-update",
    COMFLOW_TASK_ID: "XLNT/ImportOrderBHUpdate",
  },
  EMBED_OUTBOUND_CUSTOMER_ORDER_REGISTER_NEW: {
    NAME: "CUSTOMER_ORDER_REGISTER_NEW",
    PATH: "/embed-customer-order-register-new",
    COMFLOW_TASK_ID: "Order/CustomerOrderRegister",
  },
  EMBED_OUTBOUND_CUSTOMER_ORDER_DELETE: {
    NAME: "CUSTOMER_ORDER_DELETE",
    PATH: "/embed-customer-order-delete",
    COMFLOW_TASK_ID: "Order/CustomerOrderDeleteSCM",
  },
  EMBED_OUTBOUND_DEVIATIONS_OUTBOUND: {
    NAME: "DEVIATIONS_OUTBOUND",
    PATH: "/embed-deviations-outbound",
    COMFLOW_TASK_ID: "Deviation/OutboundDeviation",
  },
  //COMFLOW FUNCTIONS: CUSTOMS
  EMBED_CUSTOMS_LIST: {
    NAME: "CUSTOMS_LIST",
    PATH: "/embed-customs-list",
    COMFLOW_TASK_ID: "Customs/CustomsTransactions",
  },
  EMBED_CUSTOMS_FILE_IMPORT: {
    NAME: "CUSTOMS_FILE_IMPORT",
    PATH: "/embed-customs-file-import",
    COMFLOW_TASK_ID: "Customs/ImportCustomsFile",
  },
  //COMFLOW FUNCTIONS: COMPLAINTS
  EMBED_COMPLAINTS_CUSTOMER_COMPLAINTS: {
    NAME: "CUSTOMER_COMPLAINTS",
    PATH: "/embed-customer-complaints",
    COMFLOW_TASK_ID: "Complaints/ComplaintsCustomer",
  },
  EMBED_COMPLAINTS_INTERNAL_COMPLAINTS: {
    NAME: "INTERNAL_COMPLAINTS",
    PATH: "/embed-internal-complaints",
    COMFLOW_TASK_ID: "Complaints/ComplaintsInternal",
  },
  //COMFLOW FUNCTIONS: REPORTS
  EMBED_REPORTS_MY_QLIK_SENSE_REPORTS: {
    NAME: "MY_QLIK_SENSE_REPORTS",
    PATH: "/embed-my-qlik-sense-reports",
    COMFLOW_TASK_ID: "Qlik/TPLQlikSenseLogin",
  },
  EMBED_REPORTS_DELIVERY_ORDER_REPORTS: {
    NAME: "DELIVERY_ORDER_REPORTS",
    PATH: "/embed-delivery-order-reports",
    COMFLOW_TASK_ID: "DeliveryOrder/Report",
  },
  EMBED_REPORTS_TRACK_AND_TRACE_STATISTICS: {
    NAME: "TRACK_AND_TRACE_STATISTICS",
    PATH: "/embed-track-and-trace-statistics",
    COMFLOW_TASK_ID: "TrackTrace/TrackTraceStatistics",
  },
  EMBED_REPORTS_COMPLAINTS_KPI_REPORT: {
    NAME: "COMPLAINTS_KPI_REPORT",
    PATH: "/embed-complaints-kpi-report",
    COMFLOW_TASK_ID: "Complaints/KPIReport",
  },
  EMBED_REPORTS_COMPLAINTS_KPI_REPORT_LAST_YEAR: {
    NAME: "COMPLAINTS_KPI_REPORT_LAST_YEAR",
    PATH: "/embed-complaints-kpi-report-last-year",
    COMFLOW_TASK_ID: "Complaints/KPIReportLastYear",
  },
  EMBED_REPORTS_COMPLAINTS_UPDATE_KPI_STATISTICS: {
    NAME: "COMPLAINTS_UPDATE_KPI_STATISTICS",
    PATH: "/embed-complaints-update-kpi-statistics",
    COMFLOW_TASK_ID: "Complaints/KPIStatisticsUpdate",
  },
  EMBED_REPORTS_MY_REPORTS_LINK: {
    NAME: "MY_REPORTS_LINK",
    PATH: "/embed-my-reports-link",
    COMFLOW_TASK_ID: "MyReportsLinks",
  },
  EMBED_REPORTS_MY_REPORTS: {
    NAME: "MY_REPORTS",
    PATH: "/embed-my-reports",
    COMFLOW_TASK_ID: "MyReports",
  },
  EMBED_REPORTS_MY_REPORTS_EDIT: {
    NAME: "MY_REPORTS_EDIT",
    PATH: "/embed-my-reports-edit",
    COMFLOW_TASK_ID: "Document/MyReportsEdit",
  },
  EMBED_REPORTS_MY_REPORTS_READ: {
    NAME: "MY_REPORTS_READ",
    PATH: "/embed-my-reports-read",
    COMFLOW_TASK_ID: "Document/MyReports",
  },
  EMBED_REPORTS_MY_REPORTS_CREATE: {
    NAME: "MY_REPORTS_CREATE",
    PATH: "/embed-my-reports-create",
    COMFLOW_TASK_ID: "MyReportsCreate",
  },
  //ADMINISTRATION
  EMBED_ADMIN_USERS_CONTROL_TOWER: {
    NAME: "ADMINISTRATE_USERS",
    PATH: "/embed-admin-users-control-tower",
    COMFLOW_TASK_ID: "UsersControlTower",
  },
  EMBED_ADMIN_CA_USER: {
    NAME: "CA_USER",
    PATH: "/embed-admin-ca-user",
    COMFLOW_TASK_ID: "CAUser",
  },
  EMBED_ADMIN_CA_CHANGE_PASSWORD: {
    NAME: "CA_CHANGE_PASSWORD",
    PATH: "/embed-admin-ca-change-password",
    COMFLOW_TASK_ID: "CAUserChangePassword",
  },
  EMBED_ADMIN_REDIRECT_USER: {
    NAME: "REDIRECT_USER",
    PATH: "/embed-admin-redirect-user",
    COMFLOW_TASK_ID: "Redirect/RedirectUser",
  },
  EMBED_ADMIN_CA_GOODS_OWNER: {
    NAME: "CA_GOODS_OWNER",
    PATH: "/embed-admin-ca-goods-owner",
    COMFLOW_TASK_ID: "CAGoodsOwner",
  },
  EMBED_ADMIN_GOODS_OWNER_REGION: {
    NAME: "GOODS_OWNER_REGION",
    PATH: "/embed-admin-goods-owner-region",
    COMFLOW_TASK_ID: "Administration/GoodsOwnerRegion",
  },
  EMBED_ADMIN_CARRIER_STATUS: {
    NAME: "CARRIER_STATUS",
    PATH: "/embed-admin-carrier-status",
    COMFLOW_TASK_ID: "Admin/Carrier",
  },
  EMBED_ADMIN_INTERNAL_STATUSES: {
    NAME: "INTERNAL_STATUSES",
    PATH: "/embed-admin-internal-status",
    COMFLOW_TASK_ID: "Admin/InternalStatus",
  },
  EMBED_ADMIN_CLIENT_TRANSACTION_CODES: {
    NAME: "CLIENT_TRANSACTION_CODES",
    PATH: "/embed-admin-client-transaction-code",
    COMFLOW_TASK_ID: "Transaction/Administration/ClientTransactionCode",
  },
  EMBED_ADMIN_STATUS_CODE_COLOR: {
    NAME: "STATUS_CODE_COLOR",
    PATH: "/embed-admin-status-code-color",
    COMFLOW_TASK_ID: "Status/StatusColorCode",
  },
  EMBED_ADMIN_STATUS_TRANSLATION: {
    NAME: "STATUS_TRANSLATION",
    PATH: "/embed-admin-status-translation",
    COMFLOW_TASK_ID: "Status/StatusTranslation",
  },
  EMBED_ADMIN_COLUMN_SETTINGS: {
    NAME: "COLUMN_SETTINGS",
    PATH: "/embed-admin-column-settings",
    COMFLOW_TASK_ID: "Settings/ColumnSetting",
  },
  EMBED_ADMIN_WEEK_LETTER_SUPPORT: {
    NAME: "WEEK_LETTER_SUPPORT",
    PATH: "/embed-admin-week-letter-support",
    COMFLOW_TASK_ID: "WeekLetter/WeekLetterSupport",
  },
  EMBED_ADMIN_REGIONS: {
    NAME: "REGIONS",
    PATH: "/embed-admin-regions",
    COMFLOW_TASK_ID: "Admin/Regions",
  },
  EMBED_ADMIN_COMPLAINTS_REASON_CATEGORY: {
    NAME: "COMPLAINTS_REASON_CATEGORY",
    PATH: "/embed-admin-complaints-reason-category",
    COMFLOW_TASK_ID: "Complaints/ReasonCategory",
  },
  EMBED_ADMIN_COMPLAINTS_RECLAIM_STATUS: {
    NAME: "COMPLAINTS_RECLAIM_STATUS",
    PATH: "/embed-admin-complaints-reclaim-status",
    COMFLOW_TASK_ID: "Complaints/ReclaimeStatus",
  },
  EMBED_ADMIN_COMPLAINTS_MEASURE_CODE: {
    NAME: "COMPLAINTS_MEASURE_CODES",
    PATH: "/embed-admin-complaints-measure-code",
    COMFLOW_TASK_ID: "Complaints/MeasureCode",
  },
  EMBED_ADMIN_COMPLAINTS_REASON_CODES: {
    NAME: "COMPLAINTS_REASON_CODES",
    PATH: "/embed-admin-complaints-reason-codes",
    COMFLOW_TASK_ID: "Complaints/ReasonCodes",
  },
  EMBED_ADMIN_COMPLAINTS_DAMAGE_CODES: {
    NAME: "COMPLAINTS_DAMAGE_CODES",
    PATH: "/embed-admin-complaints-damage-codes",
    COMFLOW_TASK_ID: "Complaints/DamageCodes",
  },
  EMBED_ADMIN_COMPLAINTS_KPI_TEXTS: {
    NAME: "COMPLAINTS_KPI_TEXTS",
    PATH: "/embed-admin-complaints-kpi-texts",
    COMFLOW_TASK_ID: "Complaints/KPITexts",
  },
  EMBED_ADMIN_COMPLAINTS_ATTACHMENT_TYPES: {
    NAME: "COMPLAINTS_ATTACHMENT_TYPES",
    PATH: "/embed-admin-complaints-attachment-types",
    COMFLOW_TASK_ID: "Complaints/AttachmentTypes",
  },
  EMBED_ADMIN_COMPLAINTS_EMAIL_BUYER: {
    NAME: "COMPLAINTS_EMAIL_BUYER",
    PATH: "/embed-admin-complaints-email-buyer",
    COMFLOW_TASK_ID: "Complaints/EmailBuyer",
  },
  EMBED_ADMIN_COMPLAINTS_EMAIL_GREEN_CARGO: {
    NAME: "COMPLAINTS_EMAIL_GREEN_CARGO",
    PATH: "/embed-admin-complaints-email-green-cargo",
    COMFLOW_TASK_ID: "Complaints/EmailGreenCargo",
  },
  EMBED_ADMIN_COMPLAINTS_RECLAIM_GROUPS: {
    NAME: "COMPLAINTS_RECLAIM_GROUPS",
    PATH: "/embed-admin-complaints-reclaim-groups",
    COMFLOW_TASK_ID: "Complaints/ReclaimeGroup",
  },
  EMBED_ADMIN_COMPLAINTS_RECLAIM_STATUS_GOODS_OWNER: {
    NAME: "COMPLAINTS_RECLAIM_STATUS_GOODS_OWNER",
    PATH: "/embed-admin-complaints-reclaim-status-goods-owner",
    COMFLOW_TASK_ID: "Complaints/ReclaimeStatusGoodsOwner",
  },
  EMBED_ADMIN_COMPLAINTS_REASON_CODES_API: {
    NAME: "COMPLAINTS_REASON_CODES_API",
    PATH: "/embed-admin-complaints-reason-codes-api",
    COMFLOW_TASK_ID: "Complaints/ReasonCodesAPI",
  },
  EMBED_ADMIN_COMPLAINTS_API: {
    NAME: "COMPLAINTS_API",
    PATH: "/embed-admin-complaints-api",
    COMFLOW_TASK_ID: "Complaints/ComplaintsAPI",
  },
  EMBED_ADMIN_COMPLAINTS_RECLAIM_LINE_STATUS: {
    NAME: "COMPLAINTS_RECLAIM_LINE_STATUS",
    PATH: "/embed-admin-complaints-reclaim-line-status",
    COMFLOW_TASK_ID: "Complaints/ReclaimeLineStatus",
  },
  EMBED_ADMIN_COMPLAINTS_END_TEXTS_GOODS_OWNER: {
    NAME: "COMPLAINTS_END_TEXTS_GOODS_OWNER",
    PATH: "/embed-admin-complaints-end-texts-goods-owner",
    COMFLOW_TASK_ID: "Complaints/EndTextsGoodsOwner",
  },
  EMBED_ADMIN_INBOUND_BASIC_DATA: {
    NAME: "INBOUND_BASIC_DATA",
    PATH: "/embed-admin-inbound-basic-data",
    ISAK_ROUTE_ID: "SCMGSI001",
    COMFLOW_TASK_ID: "Isak/SCMGSI001",
  },
  EMBED_ADMIN_INBOUND_GENERATE_CALENDAR: {
    NAME: "INBOUND_GENERATE_CALENDAR",
    PATH: "/embed-admin-inbound-generate-calendar",
    ISAK_ROUTE_ID: "SCMGSI002",
    COMFLOW_TASK_ID: "Isak/SCMGSI002",
  },
  EMBED_ADMIN_DEVIATION_ACTION_CODES: {
    NAME: "DEVIATION_ACTION_CODES",
    PATH: "/embed-admin-deviation-action-codes",
    COMFLOW_TASK_ID: "Deviation/DeviationActionCode",
  },
  EMBED_ADMIN_DEVIATION_GROUPS: {
    NAME: "DEVIATION_GROUPS",
    PATH: "/embed-admin-deviation-groups",
    COMFLOW_TASK_ID: "Deviation/DeviationGroup",
  },
  EMBED_ADMIN_DEVIATION_REASON_CATEGORIES: {
    NAME: "DEVIATION_REASON_CATEGORIES",
    PATH: "/embed-admin-deviation-reason-categories",
    COMFLOW_TASK_ID: "Deviation/DeviationReasonCategory",
  },
  EMBED_ADMIN_DEVIATION_REASON_CODES: {
    NAME: "DEVIATION_REASON_CODES",
    PATH: "/embed-admin-deviation-reason-codes",
    COMFLOW_TASK_ID: "Deviation/DeviationReasonCode",
  },
  EMBED_ADMIN_DEVIATION_STATUS: {
    NAME: "DEVIATION_STATUS",
    PATH: "/embed-admin-deviation-status",
    COMFLOW_TASK_ID: "Deviation/DeviationStatus",
  },
  EMBED_ADMIN_DEVIATION_EMAIL_GOODS_OWNER: {
    NAME: "DEVIATION_EMAIL_GOODS_OWNER",
    PATH: "/embed-admin-deviation-email-goodsowner",
    COMFLOW_TASK_ID: "Deviation/DeviationEmailBuyer",
  },
  EMBED_ADMIN_DEVIATION_POST_NORD_EMAIL: {
    NAME: "DEVIATION_POST_NORD_EMAIL",
    PATH: "/embed-admin-deviation-post-nord-email",
    COMFLOW_TASK_ID: "Deviation/DeviationPostNordEmail",
  },
  EMBED_ADMIN_ARRIVAL_REGISTRATION_MY_PRINTER: {
    NAME: "ARRIVAL_REGISTRATION_MY_PRINTER",
    PATH: "/embed-admin-arrival-registration-my-printer",
    COMFLOW_TASK_ID: "MyPrinter",
  },
  EMBED_ADMIN_ADJUSTMENT_ORDER_ADJUSTMENT_CODES: {
    NAME: "ADJUSTMENT_ORDER_ADJUSTMENT_CODES",
    PATH: "/embed-admin-adjustment-order-adjustment-codes",
    COMFLOW_TASK_ID: "AdjustmentOrder/AdjustmentCodes",
  },
  EMBED_ADMIN_ADJUSTMENT_ORDER_ADJUSTMENT_ORDER_TYPE: {
    NAME: "ADJUSTMENT_ORDER_ADJUSTMENT_ORDER_TYPE",
    PATH: "/embed-admin-adjustment-order-adjustment-order-type",
    COMFLOW_TASK_ID: "AdjustmentOrder/OrderTypeAdjustment",
  },
  EMBED_ADMIN_RETURN_MAIN_INFO_GOODS_OWNER: {
    NAME: "RETURN_MAIN_INFO_GOODS_OWNER",
    PATH: "/embed-admin-return-main-info-goods-owner",
    COMFLOW_TASK_ID: "ReturGoodsOwner",
  },
  EMBED_ADMIN_RETURN_TYPES: {
    NAME: "RETURN_TYPES",
    PATH: "/embed-admin-return-types",
    COMFLOW_TASK_ID: "ReturTypes",
  },
  EMBED_ADMIN_RETURN_STATUS: {
    NAME: "RETURN_STATUS",
    PATH: "/embed-admin-return-status",
    COMFLOW_TASK_ID: "ReturStatus",
  },
  EMBED_ADMIN_RETURN_RECEIVER_ADDRESS: {
    NAME: "RETURN_RECEIVER_ADDRESS",
    PATH: "/embed-admin-return-receiver-address",
    COMFLOW_TASK_ID: "ReceiverAddresses",
  },
  EMBED_ADMIN_RETURN_PACKAGE_TYPES: {
    NAME: "RETURN_PACKAGE_TYPES",
    PATH: "/embed-admin-return-package-types",
    COMFLOW_TASK_ID: "PackageTypes",
  },
  EMBED_ADMIN_RETURN_CARRIER_SERVICES: {
    NAME: "RETURN_CARRIER_SERVICES",
    PATH: "/embed-admin-return-carrier-services",
    COMFLOW_TASK_ID: "ReturCarrierServices",
  },
  EMBED_ADMIN_RETURN_PRINTERS: {
    NAME: "RETURN_PRINTERS",
    PATH: "/embed-admin-return-printer",
    COMFLOW_TASK_ID: "ReturPrinters",
  },
  //SYSTEM ADMINISTRATION
  EMBED_SYSADMIN_SYSTEM_CONTROL: {
    NAME: "SYSTEM_CONTROL",
    PATH: "/embed-sysadmin-system-control",
    COMFLOW_TASK_ID: "System/SystemStatus",
  },
  EMBED_SYSADMIN_USER_SESSIONS: {
    NAME: "USER_SESSIONS",
    PATH: "/embed-sysadmin-user-sessions",
    COMFLOW_TASK_ID: "System/UserSessions",
  },
  EMBED_SYSADMIN_VIRTUAL_SESSIONS: {
    NAME: "VIRTUAL_SESSIONS",
    PATH: "/embed-sysadmin-virtual-sessions",
    COMFLOW_TASK_ID: "VirtualSession/VirtualSession",
  },
  EMBED_SYSADMIN_PATCH_LEVEL: {
    NAME: "PATCH_LEVEL",
    PATH: "/embed-sysadmin-patch-level",
    COMFLOW_TASK_ID: "System/PatchLevel",
  },
  EMBED_SYSADMIN_LOGGER_OTS_LIB: {
    NAME: "LOGGER_OTS_LIB",
    PATH: "/embed-sysadmin-logger-ots-lib",
    COMFLOW_TASK_ID: "Logger/LoggerOTS",
  },
  EMBED_SYSADMIN_USER_LOGIN_HISTORY: {
    NAME: "USER_LOGIN_HISTORY",
    PATH: "/embed-sysadmin-user-login-history",
    COMFLOW_TASK_ID: "UsersLog",
  },
  EMBED_SYSADMIN_USER_TASK_LOG: {
    NAME: "USER_TASK_LOG",
    PATH: "/embed-sysadmin-user-task-log",
    COMFLOW_TASK_ID: "User/UserTaskLog",
  },
  EMBED_SYSADMIN_CUSTOMER_LOG: {
    NAME: "CUSTOMER_LOG",
    PATH: "/embed-sysadmin-customer-log",
    COMFLOW_TASK_ID: "Logger/CustomerLog",
  },
  EMBED_SYSADMIN_APPLICATION_ERROR_LOG: {
    NAME: "APPLICATION_ERROR_LOG",
    PATH: "/embed-sysadmin-application-error-log",
    COMFLOW_TASK_ID: "ApplicationErrorLog",
  },
  EMBED_SYSADMIN_SYSTEM_STATISTICS: {
    NAME: "SYSTEM_STATISTICS",
    PATH: "/embed-sysadmin-system-statistics",
    COMFLOW_TASK_ID: "Statistics/Statistics",
  },
  EMBED_SYSADMIN_JOB_SCHEDULER_OTS_LIB: {
    NAME: "JOB_SCHEDULER_OTS_LIB",
    PATH: "/embed-sysadmin-job-scheduler-ots-lib",
    COMFLOW_TASK_ID: "Job/JobMonitorOTS",
  },
  EMBED_SYSADMIN_EMAIL: {
    NAME: "EMAIL_OTS_LIB",
    PATH: "/embed-sysadmin-email",
    COMFLOW_TASK_ID: "Notifications/EmailsOTS",
  },
  EMBED_SYSADMIN_EMAIL_TEMPLATES: {
    NAME: "EMAIL_TEMPLATES",
    PATH: "/embed-sysadmin-email-templates",
    COMFLOW_TASK_ID: "Notifications/EmailTemplatesOTS",
  },
  EMBED_SYSADMIN_SMS: {
    NAME: "SMS",
    PATH: "/embed-sysadmin-sms",
    COMFLOW_TASK_ID: "Notifications/SMS",
  },
  EMBED_SYSADMIN_SMS_TEMPLATES: {
    NAME: "SMS_TEMPLATES",
    PATH: "/embed-sysadmin-sms-templates",
    COMFLOW_TASK_ID: "Notifications/SMSMessageTemplate",
  },
  EMBED_SYSADMIN_FILES_OTS_LIB: {
    NAME: "FILES_OTS_LIB",
    PATH: "/embed-sysadmin-files-ots-lib",
    COMFLOW_TASK_ID: "Documents/FilesAllOTS",
  },
  EMBED_SYSADMIN_USERS: {
    NAME: "USERS",
    PATH: "/embed-sysadmin-users",
    COMFLOW_TASK_ID: "Users",
  },
  EMBED_SYSADMIN_USER_GROUPS: {
    NAME: "USER_GROUPS",
    PATH: "/embed-sysadmin-groups",
    COMFLOW_TASK_ID: "Groups",
  },
  EMBED_SYSADMIN_USER_ROLES: {
    NAME: "USER_ROLES",
    PATH: "/embed-sysadmin-user-roles",
    COMFLOW_TASK_ID: "Roles",
  },
  EMBED_SYSADMIN_ADVANCED_AUTHENTICATION: {
    NAME: "ADVANCED_AUTHENTICATION",
    PATH: "/embed-sysadmin-advanced-authentication",
    COMFLOW_TASK_ID: "AdvancedAuthorization/AdvancedAuthorization",
  },
  EMBED_SYSADMIN_PASSWORD_POLICIES: {
    NAME: "PASSWORD_POLICIES",
    PATH: "/embed-sysadmin-password-policy",
    COMFLOW_TASK_ID: "PasswordPolicy/PasswordPolicy",
  },
  EMBED_SYSADMIN_FUNCTION_AUTHORIZATION: {
    NAME: "FUNCTION_AUTHORIZATION",
    PATH: "/embed-sysadmin-function-authorization",
    COMFLOW_TASK_ID: "Authorization",
  },
  EMBED_SYSADMIN_FUNCTION_GROUPS: {
    NAME: "FUNCTION_GROUPS",
    PATH: "/embed-sysadmin-function-groups",
    COMFLOW_TASK_ID: "TaskGroupMaintenance",
  },
  EMBED_SYSADMIN_REALM: {
    NAME: "REALM",
    PATH: "/embed-sysadmin-realm",
    COMFLOW_TASK_ID: "AuthorizationRealm",
  },
  EMBED_SYSADMIN_KEY_MANAGEMENT: {
    NAME: "KEY_MANAGEMENT",
    PATH: "/embed-sysadmin-key-management",
    COMFLOW_TASK_ID: "AdvancedAuthorization/KeyManagement",
  },
  EMBED_SYSADMIN_WEB_AND_REST_SERVICES: {
    NAME: "WEB_AND_REST_SERVICES",
    PATH: "/embed-sysadmin-web-and-rest-services",
    COMFLOW_TASK_ID: "System/WS-Rest-Services",
  },
  EMBED_SYSADMIN_EMAIL_TEST: {
    NAME: "EMAIL_TEST",
    PATH: "/embed-sysadmin-email-test",
    COMFLOW_TASK_ID: "Library/EmailTest",
  },
  EMBED_SYSADMIN_LOCALES: {
    NAME: "LOCALES",
    PATH: "/embed-sysadmin-locales",
    COMFLOW_TASK_ID: "Locales",
  },
  EMBED_SYSADMIN_NUMBER_SERIES_OTS: {
    NAME: "NUMBER_SERIES_OTS",
    PATH: "/embed-sysadmin-number-series-ots",
    COMFLOW_TASK_ID: "NumberSeries/NumberSeriesOTS",
  },
  EMBED_SYSADMIN_SETTINGS_OTS_LIB: {
    NAME: "SETTINGS_OTS_LIB",
    PATH: "/embed-sysadmin-settings-ots-lib",
    COMFLOW_TASK_ID: "Settings/SettingsOTS",
  },
  EMBED_SYSADMIN_CONSTANT_COLUMNS: {
    NAME: "CONSTANT_COLUMNS",
    PATH: "/embed-sysadmin-constant-columns",
    COMFLOW_TASK_ID: "Constants/Constants",
  },
  EMBED_SYSADMIN_LICENSE_FILES: {
    NAME: "LICENSE_FILES",
    PATH: "/embed-sysadmin-license-files",
    COMFLOW_TASK_ID: "License/LicenseAccessFiles_Manage",
  },
  EMBED_SYSADMIN_INSTALLED_LICENSE_OBJECTS: {
    NAME: "INSTALLED_LICENSE_OBJECTS",
    PATH: "/embed-sysadmin-installed-license-objects",
    COMFLOW_TASK_ID: "License/LicenseObjects_Manage",
  },
  EMBED_SYSADMIN_LICENSE_ACCESS: {
    NAME: "LICENSE_ACCESS",
    PATH: "/embed-sysadmin-license-access",
    COMFLOW_TASK_ID: "License/LicensAccess_Manage",
  },
  EMBED_SYSADMIN_LICENSED_USERS: {
    NAME: "LICENSED_USERS",
    PATH: "/embed-sysadmin-licensed-users",
    COMFLOW_TASK_ID: "License/LicenseUser_Manage",
  },
  EMBED_SYSADMIN_LICENSED_USER_FUNCTIONS: {
    NAME: "LICENSED_USER_FUNCTIONS",
    PATH: "/embed-sysadmin-licensed-user-functions",
    COMFLOW_TASK_ID: "License/LicenseUserFunctions_Manage",
  },
  EMBED_SYSADMIN_LICENSED_USER_WORKSTATIONS: {
    NAME: "LICENSED_USER_WORKSTATIONS",
    PATH: "/embed-sysadmin-licensed-user-workstations",
    COMFLOW_TASK_ID: "License/LicenseUserWorkstation_Manage",
  },
  EMBED_SYSADMIN_LICENSE_WORKSTATIONS: {
    NAME: "LICENSE_WORKSTATIONS",
    PATH: "/embed-sysadmin-license-workstations",
    COMFLOW_TASK_ID: "License/LicenseWorkstations",
  },
  EMBED_SYSADMIN_LICENSE_ALLOCATION_FOR_USER_GROUP: {
    NAME: "LICENSE_ALLOCATION_FOR_USER_GROUP",
    PATH: "/embed-sysadmin-license-allocation-for-user-group",
    COMFLOW_TASK_ID: "License/LicenseAccessGroups",
  },
  EMBED_SYSADMIN_FIELD_AUDIT_TRAIL_INQUIRY: {
    NAME: "FIELD_AUDIT_TRAIL_INQUIRY",
    PATH: "/embed-sysadmin-field-audit-trail-inquiry",
    COMFLOW_TASK_ID: "AuditTrail/FCAT_Inquiry",
  },
  EMBED_SYSADMIN_FIELD_AUDIT_REASON_CODES: {
    NAME: "FIELD_AUDIT_REASON_CODES",
    PATH: "/embed-sysadmin-field-audit-reason-codes",
    COMFLOW_TASK_ID: "AuditTrail/FCAT_ReasonCodes",
  },
  EMBED_SYSADMIN_FIELD_AUDIT_TRAIL_CONTROL: {
    NAME: "FIELD_AUDIT_TRAIL_CONTROL",
    PATH: "/embed-sysadmin-field-audit-trail-control",
    COMFLOW_TASK_ID: "AuditTrail/FCAuditTrail",
  },
  EMBED_SYSADMIN_USER_ACCESS_MESSAGES: {
    NAME: "USER_ACCESS_MESSAGES",
    PATH: "/embed-sysadmin-user-access-messages",
    COMFLOW_TASK_ID: "UserAccessMessages/UserAccessMessages",
  },
  EMBED_SYSADMIN_USER_ACCESS_MESSAGES_EXCEPTIONS: {
    NAME: "USER_ACCESS_MESSAGES_EXCEPTIONS",
    PATH: "/embed-sysadmin-user-access-messages-exceptions",
    COMFLOW_TASK_ID: "UserAccessMessages/UserAccessMessagesExceptions_Manage",
  },
  EMBED_SYSADMIN_USER_ACCESS_MESSAGES_READ_LOG: {
    NAME: "USER_ACCESS_MESSAGES_READ_LOG",
    PATH: "/embed-sysadmin-user-access-messages-read-log",
    COMFLOW_TASK_ID: "UserAccessMessages/UserAccessMessageReadLog_Manage",
  },
  //Old embedded
  EMBED_INBOUND_ORDER_PURCHASE_ORDER: {
    NAME: "PURCHASE_ORDER_REQUEST",
    PATH: "/embed-order-purchase-order",
    COMFLOW_TASK_ID: "Order/PurchaseOrder",
  },
  EMBED_WAREHOUSE_ITEMS_REQUEST: {
    NAME: "ITEMS_REQUEST",
    PATH: "/embed-items-request",
    COMFLOW_TASK_ID: "Item/Items",
  },
  EMBED_WAREHOUSE_TRANSACTIONS: {
    NAME: "INVENTORY_TRANSACTIONS",
    PATH: "/embed-transactions",
    COMFLOW_TASK_ID: "Transaction/Transaction",
  },
  //Not embedded
  ADMINISTRATION: {
    PATH: "/administration",
    NAME: "ADMINISTRATION",
  },
  CUSTOMER_ORDER_IMPORT: {
    PATH: "/customer-order/import",
    NAME: "CUSTOMER_ORDER_IMPORT",
    FUNCTION_ID: "ControlTower_UploadCustomerOrderFilesToWms",
  },
  FILE_UPLOAD: {
    PATH: "/fileupload",
    NAME: "FILE_UPLOAD_TITLE",
  },
  ITEM_CENTER: {
    PATH: "/items/:itemId",
    NAME: "ITEM_CENTER",
  },
  ITEM_DETAIL: {
    PATH: "/items/:itemId/detail",
    NAME: "ITEM_DETAIL",
  },
  ITEM_PAGE: {
    PATH: "/items",
    NAME: "ITEM_QUESTION",
  },
  LOGOUT: {
    PATH: "/logout",
    NAME: "LOGOUT",
  },
  MY_CASES: {
    PATH: "/my-page/cases",
    NAME: "SUB_NAV_MY_CASES",
  },
  MY_PAGE: {
    PATH: "/my-page",
    NAME: "MY_PAGE_TITLE",
  },
  MY_PASSWORD: {
    PATH: "/my-page/password",
    NAME: "SUB_NAV_MY_PASSWORD",
  },
  MY_ACCOUNT: {
    PATH: "/my-page/my-account",
    NAME: "SUB_NAV_MY_ACCOUNT",
  },
  NEWS_DETAIL: {
    PATH: "/news-detail/:id",
    NAME: "NEWS_DETAIL_TITLE",
  },
  ONE_TRACE: {
    NAME: "ONE_TRACE",
    EXTERNAL_LINK: "https://agent.addimotion.com/",
    TASK_GROUP_ID: "OneTrace",
  },
  PURCHASE_ORDERS: {
    PATH: "/purchase-orders",
    NAME: "PURCHASE_ORDER_REQUEST",
    FUNCTION_ID: "ControlTower_GetPurchaseOrders",
  },
  PURCHASE_ORDER_DETAIL: {
    PATH: "/purchase-orders/:purchaseOrder",
    NAME: "PURCHASE_ORDER_DETAIL",
    FUNCTION_ID: "ControlTower_GetPurchaseOrder",
  },
  PURCHASE_ORDER_IMPORT: {
    PATH: "/purchase-order/import",
    NAME: "PURCHASE_ORDER_IMPORT",
    FUNCTION_ID: "ControlTower_UploadPurchaseOrderFilesToWms",
  },
  REPORTS: {
    PATH: "/reports",
    NAME: "REPORTS",
  },
  RESET_PASSWORD: {
    PATH: "/password/reset",
    NAME: "RESET_PASSWORD_TITLE",
  },
  SUB_NAV_MY_PERSONAL_DATA: {
    PATH: "/my-page/my-account",
    NAME: "SUB_NAV_MY_PERSONAL_DATA",
  },
  SYSTEM_ADMINISTRATION: {
    PATH: "/system-administration",
    NAME: "SYSTEM_ADMINISTRATION",
  },
  TRACK_AND_TRACE: {
    NAME: "TRACK_AND_TRACE",
    PATH: "/track-and-trace",
    FUNCTION_ID: "ControlTower_GetCustomerOrders",
  },
  TRACK_AND_TRACE_CUSTOMER_ORDER_DETAIL: {
    NAME: "TRACK_AND_TRACE_CUSTOMER_ORDER_DETAIL",
    PATH: "/track-and-trace/customer-order/:customerOrder",
    FUNCTION_ID: "ControlTower_GetCustomerOrders",
  },
  TRACK_AND_TRACE_CUSTOMER_ORDER_PACKAGE_DETAIL: {
    NAME: "TRACK_AND_TRACE_CUSTOMER_ORDER_PACKAGE_DETAIL",
    PATH: "/track-and-trace/customer-order-package/:customerOrderPackage",
    FUNCTION_ID: "ControlTower_GetCustomerOrders",
  },
  TRACK_AND_TRACE_SHIPMENT_DETAIL: {
    NAME: "TRACK_AND_TRACE_SHIPMENT_DETAIL",
    PATH: "/track-and-trace/shipment/:shipment",
    FUNCTION_ID: "ControlTower_GetShipments",
  },
  USER_SETTINGS: {
    PATH: "/settings",
    NAME: "USER_SETTINGS",
  },
  WAREHOUSE_TRANSACTIONS: {
    PATH: "/warehouse-transactions",
    NAME: "WAREHOUSE_TRANSACTIONS",
    FUNCTION_ID: "ControlTower_GetWarehouseTransactions",
  },
  ZETES_IMAGES: {
    PATH: "/embed-zetes-images/:customerOrderPackage",
    NAME: "ZETES_IMAGES",
  },
});

export default pages;
