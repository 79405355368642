import { useMemo } from "react";
import useAuthorization from "../useAuthorizations";
import { MenuItem, SubMenu, SubMenuItem } from "./dataTypes";
import { createMenu } from "./menu";

export default function useMenu(menuId: Parameters<typeof createMenu>[0]) {
  const { isAuthorizedToTask, isAuthorizedToTaskGroup, isAuthorizedToFunction } = useAuthorization();

  const menu = useMemo(() => {
    const menu = createMenu(menuId).reduce<MenuItem[]>((menuItems, menuItem) => {
      if (
        !isAuthorized(isAuthorizedToTask, menuItem.comflowTaskId) ||
        !isAuthorized(isAuthorizedToFunction, menuItem.functionId) ||
        !isAuthorized(isAuthorizedToTaskGroup, menuItem.taskGroupId)
      )
        return menuItems;

      if (!menuItem.subMenus) return [...menuItems, menuItem];

      menuItem.subMenus = menuItem.subMenus.reduce<SubMenu[]>((subMenus, subMenu) => {
        subMenu.items = subMenu.items.reduce<SubMenuItem[]>((subMenuItems, subMenuItem) => {
          if (
            !isAuthorized(isAuthorizedToTask, subMenuItem.comflowTaskId) ||
            !isAuthorized(isAuthorizedToFunction, subMenuItem.functionId) ||
            !isAuthorized(isAuthorizedToTaskGroup, menuItem.taskGroupId)
          )
            return subMenuItems;

          return [...subMenuItems, subMenuItem];
        }, []);

        return subMenu.items.length > 0 ? [...subMenus, subMenu] : subMenus;
      }, []);

      return menuItem.subMenus.flatMap(subMenu => subMenu.items).length > 0 ? [...menuItems, menuItem] : menuItems;
    }, []);

    return menu;
  }, [menuId, isAuthorizedToTask, isAuthorizedToFunction, isAuthorizedToTaskGroup]);

  return menu;
}

function isAuthorized(
  isAuthorizedToAuthorizations: (taskId: string) => boolean,
  comflowTaskId?: string | string[],
  taskGroupId?: string | string[],
  functionId?: string | string[],
) {
  if (comflowTaskId == null) return true;
  if (Array.isArray(comflowTaskId)) return comflowTaskId.some(id => isAuthorizedToAuthorizations(id));
  if (Array.isArray(functionId)) return functionId.some(id => isAuthorizedToAuthorizations(id));
  if (Array.isArray(taskGroupId)) return taskGroupId.some(id => isAuthorizedToAuthorizations(id));

  if (comflowTaskId) {
    return isAuthorizedToAuthorizations(comflowTaskId);
  } else if (functionId) {
    return isAuthorizedToAuthorizations(functionId);
  } else if (taskGroupId) {
    return isAuthorizedToAuthorizations(taskGroupId);
  }
}
