import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { itemBatchSliceName } from "./dataTypes";
import { itemBatchAdapter } from "./itemBatchAdapter";
import { addFetchItemBatchReducers } from "./reducers/fetchItemBatch";
import { addFetchItemBatchesReducers } from "./reducers/fetchItemBatches";

const slice = createSlice({
  name: itemBatchSliceName,
  initialState: itemBatchAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchItemBatchReducers(builder);
    addFetchItemBatchesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
