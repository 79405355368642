import { Dialog, Paper, PaperProps } from "@mui/material";
import Draggable from "react-draggable";

export type PromptDialogProps<TRowItem> = {
  PromptComponent: React.ComponentType<{ onSelect: (row: TRowItem) => void }>;
  onSelectRow: (value: TRowItem) => void;
  promptOpen: boolean;
  closePrompt: () => void;
};

const PromptDialog = <TRowItem,>({
  PromptComponent,
  promptOpen,
  closePrompt,
  onSelectRow,
}: PromptDialogProps<TRowItem>) => {
  return (
    <Dialog
      open={promptOpen}
      PaperProps={{ sx: { width: "-webkit-fill-available" } }}
      PaperComponent={PaperComponent}
      onClose={closePrompt}
      hideBackdrop
    >
      {promptOpen && (
        <PromptComponent
          onSelect={item => {
            closePrompt();
            onSelectRow(item);
          }}
        />
      )}
    </Dialog>
  );
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default PromptDialog;
