import SearchIcon from "@mui/icons-material/Search";
import { Box, ButtonProps, Tab, Tabs, Typography } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import classNames from "classnames";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, useCallback, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import useTranslate from "../../language/useTranslate";
import useBreadcrumbs from "../../navigation/useBreadcrumbs";
import { ValueOf } from "../../utils/dataTypes";
import { GridState } from "../DataGrid/dataTypes";
import ItemPrompt from "../item/prompt/ItemPrompt";
import Page from "../shared/Page/Page";
import Button from "../shared/PostNord/Button/Button";
import InputField from "../shared/PostNord/InputField/InputField";
import TabPanel from "../shared/TabPanel/TabPanel";
import CustomerOrderPackagesDataGrid from "./CustomerOrderPackagesDataGrid";
import CustomerOrdersDataGrid from "./CustomerOrdersDataGrid";
import ShipmentsDataGrid from "./ShipmentDataGrid";
import styles from "./TrackAndTracePage.module.scss";

export const GRID_TYPE = {
  ORDER_LEVEL: "orderLevel",
  PACKAGE_LEVEL: "packageLevel",
  SHIPMENT_LEVEL: "shipmentLevel",
} as const;

export interface TrackAndTraceTopFilter {
  fromDate?: Date;
  toDate?: Date;
  itemSearch?: string;
  search?: string;
}

export interface TrackAndTraceTopFilterRedux {
  fromDate?: string;
  toDate?: string;
  itemSearch?: string;
  search?: string;
}

export interface TrackAndTraceState extends TrackAndTraceTopFilterRedux {
  gridType?: ValueOf<typeof GRID_TYPE>;
  gridState?: GridState;
}

const GRID_TYPE_BUTTON_BORDER_SX: ButtonProps["sx"] = {
  "& .MuiButton-outlined": {
    borderWidth: "2px",
    borderColor: "#005d92",
    "&:hover": {
      borderColor: "#005d92",
      borderWidth: "2px",
    },
  },
};

const GRID_TYPE_BUTTON_SX: ButtonProps["sx"] = {
  borderRadius: "3px",
  boxShadow: "none",
  ...GRID_TYPE_BUTTON_BORDER_SX,
};

const GRID_TYPE_BUTTON_GROUP_SX: ComponentPropsWithoutRef<typeof ButtonGroup>["sx"] = {
  ...GRID_TYPE_BUTTON_BORDER_SX,
};

const TrackAndTracePage = () => {
  const translate = useTranslate();
  const { breadcrumb } = useBreadcrumbs<TrackAndTraceState | undefined>();

  const breadcrumbData = breadcrumb?.data;

  const [breadcrumbDataState] = useState(breadcrumb?.data);

  const [gridType, setGridType] = useState<ValueOf<typeof GRID_TYPE>>(
    breadcrumbData?.gridType ?? GRID_TYPE.ORDER_LEVEL,
  );

  const [fromDate, setFromDate] = useState(
    breadcrumbData?.fromDate != null ? dayjs(breadcrumbData.fromDate).format("YYYY-MM-DD") : undefined,
  );

  const [toDate, setToDate] = useState(
    breadcrumbData?.toDate != null ? dayjs(breadcrumbData.toDate).format("YYYY-MM-DD") : undefined,
  );

  const [itemSearch, setItemSearch] = useState(breadcrumbData?.itemSearch);
  const [search, setSearch] = useState(breadcrumbData?.search);
  const [filter, setFilter] = useImmer<TrackAndTraceTopFilter>(() => {
    const fromDateParam = breadcrumbData?.fromDate;
    const toDateParam = breadcrumbData?.toDate;

    const initialFilter: TrackAndTraceTopFilter = {
      fromDate: fromDateParam ? new Date(fromDateParam) : undefined,
      toDate: toDateParam ? new Date(toDateParam) : undefined,
      itemSearch: breadcrumbData?.itemSearch,
      search: breadcrumbData?.search,
    };

    return initialFilter;
  });

  const [gridState, setGridState] = useImmer(breadcrumbData?.gridState);

  const [tabValue, setTabValue] = useState(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const fromDateParam = breadcrumbDataState?.fromDate;
    const toDateParam = breadcrumbDataState?.toDate;
    const itemSearchParam = breadcrumbDataState?.itemSearch;
    const searchParam = breadcrumbDataState?.search;

    if (fromDateParam != null) setFromDate(dayjs(fromDateParam).format("YYYY-MM-DD"));
    if (toDateParam != null) setToDate(dayjs(toDateParam).format("YYYY-MM-DD"));
    if (itemSearchParam != null) setItemSearch(itemSearchParam);
    if (searchParam != null) setSearch(searchParam);

    setFilter(draft => {
      draft.fromDate = fromDateParam ? new Date(fromDateParam) : undefined;
      draft.toDate = toDateParam ? new Date(toDateParam) : undefined;
      draft.itemSearch = itemSearchParam;
      draft.search = searchParam;
    });

    setGridState(breadcrumbDataState?.gridState);
  }, [setFilter, setGridState, breadcrumbDataState]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function handleSearch() {
    const convertedFromDate = fromDate == null || fromDate === "" ? undefined : new Date(fromDate);
    const convertedToDate = toDate == null || toDate === "" ? undefined : new Date(toDate);

    setFilter(draft => {
      draft.fromDate = convertedFromDate;
      draft.toDate = convertedToDate;
      draft.itemSearch = itemSearch;
      draft.search = search;
    });

    setGridState(undefined); // search will reset the data in the grid
  }

  const getGridTypeButtonProps = useCallback(
    (labelId: string, thisGridType: ValueOf<typeof GRID_TYPE>) => ({
      labelId,
      isSelected: gridType === thisGridType,
      gridType: thisGridType,
      onClick: () => {
        setGridType(thisGridType);
        setGridState(undefined);
      },
    }),
    [gridType, setGridState],
  );

  return (
    <Page customCssClass={styles.trackAndTracePage} spacingTop>
      <h1>{translate("TRACK_AND_TRACE")}</h1>

      <Box>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={translate("OUTBOUND_TRACK_AND_TRACE")} {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={0} index={0}>
        <div className={styles.filterContainer}>
          <section className={classNames(styles.filter)}>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <div className={classNames(styles.flexContainer, styles.dateFilter)}>
                <DatePicker
                  label={translate("FROM_ORDER_DATE")}
                  value={fromDate ? dayjs(fromDate) : null}
                  onChange={value => setFromDate(value?.format("YYYY-MM-DD") ?? "")}
                  slotProps={{
                    field: { clearable: true },
                    textField: { size: "small" },
                  }}
                  className={styles.fromDateFilter}
                  format="YYYY-MM-DD"
                />
                <p>-</p>
                <DatePicker
                  label={translate("TO_ORDER_DATE")}
                  value={toDate ? dayjs(toDate) : null}
                  onChange={value => setToDate(value?.format("YYYY-MM-DD") ?? "")}
                  className={styles.toDateFilter}
                  slotProps={{
                    field: { clearable: true },
                    textField: { size: "small" },
                  }}
                  format="YYYY-MM-DD"
                />
              </div>

              <Box marginBottom={"1.6rem"}>
                <InputField
                  type="search"
                  size="small"
                  showClearButton={true}
                  onClear={() => setItemSearch("")}
                  label={translate("ITEM_NUMBER_SHORT")}
                  value={itemSearch ?? ""}
                  onChange={e => setItemSearch(e.target.value)}
                  usePrompt={true}
                  PromptComponent={ItemPrompt}
                  onSelectPromptRow={item => {
                    setItemSearch(item.clientItemNumber);
                  }}
                />
              </Box>

              <div className={classNames(styles.flexContainer, styles.searchFilter)}>
                <InputField
                  label={translate("TRACK_AND_TRACE_PAGE_SEARCH")}
                  value={search ?? ""}
                  onChange={e => setSearch(e.target.value)}
                  customCssClass={styles.searchInputField}
                  size="small"
                  showClearButton={true}
                  onClear={() => setSearch("")}
                  sx={{ borderRadius: "150px" }}
                />
                <Button label={translate("SEARCH")} variant="contained" endIcon={<SearchIcon />} type="submit" />
              </div>
            </form>
          </section>
          <section className={classNames(styles.gridPicker)}>
            <Typography variant="h6" sx={{ margin: "0 5rem 0.5rem 0", fontSize: "1.2rem" }}>
              {translate("SHOW_RESULT_ON")}:
            </Typography>
            <ButtonGroup color="primary" orientation="horizontal" variant="outlined" sx={GRID_TYPE_BUTTON_GROUP_SX}>
              <GridTypeButton {...getGridTypeButtonProps("ORDER_LEVEL", GRID_TYPE.ORDER_LEVEL)} />
              <GridTypeButton {...getGridTypeButtonProps("PACKAGE_LEVEL", GRID_TYPE.PACKAGE_LEVEL)} />
              <GridTypeButton {...getGridTypeButtonProps("SHIPMENT_LEVEL", GRID_TYPE.SHIPMENT_LEVEL)} />
            </ButtonGroup>
          </section>
        </div>
        <section>
          {gridType === GRID_TYPE.ORDER_LEVEL ? (
            <CustomerOrdersDataGrid topFilter={filter} gridState={gridState} />
          ) : gridType === GRID_TYPE.PACKAGE_LEVEL ? (
            <CustomerOrderPackagesDataGrid topFilter={filter} gridState={gridState} />
          ) : (
            <ShipmentsDataGrid topFilter={filter} gridState={gridState} />
          )}
        </section>
      </TabPanel>
    </Page>
  );
};

const GridTypeButton = ({
  labelId,
  gridType,
  isSelected,
  onClick,
}: {
  labelId: string;
  gridType: ValueOf<typeof GRID_TYPE>;
  isSelected: boolean;
  onClick: (gridType: ValueOf<typeof GRID_TYPE>) => void;
}) => {
  const translate = useTranslate();

  return (
    <Button
      label={translate(labelId)}
      size="small"
      sx={GRID_TYPE_BUTTON_SX}
      variant={isSelected ? "contained" : "outlined"}
      onClick={() => onClick(gridType)}
    />
  );
};

export default TrackAndTracePage;
