import { useCallback, useMemo } from "react";
import useLazyGetSessionData from "../features/session/hooks/useLazyGetSessionData";

export default function useAuthorization() {
  const { sessionData } = useLazyGetSessionData({ loadIfNotInitialized: true });
  const authorizations = sessionData?.authorizations;
  const isAdmin = sessionData?.isAdmin;
  const authorizationIds = useMemo(
    () => ({
      taskIds: new Set(authorizations?.filter(item => item.type === "TASK").map(task => task.id)),
      taskGroupIds: new Set(authorizations?.filter(item => item.type === "TASK_GROUP").map(taskGroup => taskGroup.id)),
      functionIds: new Set(authorizations?.filter(item => item.type === "FUNCTION").map(task => task.id)),
    }),
    [authorizations],
  );

  const isAuthorizedToTask = useCallback(
    (taskId: string) => {
      return isAdmin === true || authorizationIds.taskIds.has(taskId);
    },
    [isAdmin, authorizationIds.taskIds],
  );

  const isAuthorizedToFunction = useCallback(
    (functionId: string) => {
      return isAdmin === true || authorizationIds.functionIds.has(functionId);
    },
    [isAdmin, authorizationIds.functionIds],
  );

  const isAuthorizedToTaskGroup = useCallback(
    (taskGroupId: string) => {
      return isAdmin === true || authorizationIds.taskGroupIds.has(taskGroupId);
    },
    [isAdmin, authorizationIds.taskGroupIds],
  );

  return { isAdmin, isAuthorizedToTask, isAuthorizedToTaskGroup, isAuthorizedToFunction };
}
