import en from "../en";

const sv: typeof en.translation = {
  ACCESS_CARD_TITLE: "Passerkort",
  ADD_ATTACHMENTS: "Lägg till bilagor",
  ADDRESS: "Adress",
  ADJUSTMENT_ORDER_ADJUSTMENT_CODES: "Nyutskicksorder / Justeringskoder",
  ADJUSTMENT_ORDER_ADJUSTMENT_ORDER_TYPE: "Nyutskicksorder / Ordertyper justering",
  ADMINISTRATE_USERS: "Administrera användare",
  ADVANCED_AUTHENTICATION: "Avancerad autentisering",
  ALREADY_LOGGED_IN: "Du är redan inloggad",
  ARRIVAL_REGISTRATION_MY_PRINTER: "Ankomstregistrering / Min skrivare",
  ADMINISTRATION: "Administration",
  ALERT_ERROR_TITLE: "Fel",
  ALERT_INFO_TITLE: "Info",
  ALERT_SUCCESS_TITLE: "Success",
  ALERT_WARNING_TITLE: "Varning",
  ALL: "Alla",
  AMOUNT: "Antal",
  AMOUNT_OF_PACKAGES: "Antal kolli",
  APPLICATION_ERROR_LOG: "Applikationsfel logg",
  APPROVE: "Godkänn",
  ATTACH: "Bifoga",
  ATTACHMENTS: "Bilagor",
  ARRIVAL_REGISTRATIONS: "Ankomstregistreringar",
  AUTHORIZATION: "Behörighet",
  AVAILABLE: "Tillgängligt",
  BACK: "Tillbaka",
  BACK_TO_START: "Tillbaka till Start",
  BALANCE: "Saldo",
  BALANCE_INFORMATION: "Saldoinformation",
  BAR_CODE: "Streckkod",
  BATCH: "Batch",
  BATCH_NUMBER_MANAGEMENT: "Batchnummerhantering",
  BEST_BEFORE: "Bäst före",
  BLOCK_INFO: "Spärrinfo",
  BLOCKING_WAREHOUSE: "Spärrlager",
  BOOKING_SHOW: "Bokning - visa",
  BOOKING_UPDATE: "Bokning - uppdatera",
  CA_USER: "Administrera användare - admin",
  CA_CHANGE_PASSWORD: "Användare - ändra lösenord",
  CA_GOODS_OWNER: "Administrera varuägare - admin",
  CANCEL: "Avbryt",
  CANNOT_SHOW_IMAGE_OF_PACKAGE: "Kan inte visa bild på kolli",
  CARRIER: "Transportör",
  CARRIER_EVENTS: "Transportörshändelser",
  CARRIER_DETAILS: "Transportörsinformation",
  CARRIER_PRODUCT: "Transportörsprodukt",
  CARRIER_STATUS: "Transportör status",
  CASE: "Ärende",
  CASE_NOT_FOUND: "Ärendet kunde inte hittas",
  CHANGE_PASSWORD: "Byt lösenord",
  CHANGE_GOODS_OWNER: "Byt varuägare",
  CHOOSE_FILE: "Välj fil",
  CHOOSE_GOODS_OWNER: "Välj varuägare",
  CHANGE_LOG: "Ändringslogg",
  CITY: "Ort",
  CLEAR: "Rensa",
  CLICK_LINK_OPEN_AGAIN: "Klicka på länken för att öppna sidan igen",
  CLIENT_TRANSACTION_CODES: "Klientens transaktionskoder",
  CLOSE: "Stäng",
  CLOSE_THIS_TAB: "Stäng denna flik",
  CLOSED: "Stängda",
  CM: "cm",
  COMFLOW_SYSTEM_SETTINGS: "CF-Systeminställningar",
  COMFLOW_USER_SESSIONS: "CF-Användarsessioner",
  COMPLAINTS_INTERNAL: "Reklamationer - intern",
  COMFLOW_CHANGE_GOODS_OWNER: "Byt varuägare",
  COMPLAINTS: "Reklamationer",
  COMPLAINTS_API: "Reklamationer API",
  COMPLAINTS_ATTACHMENT_TYPES: "Tillåtna typer på bilagor",
  COMPLAINTS_DAMAGE_CODES: "Koder för skador",
  COMPLAINTS_EMAIL_BUYER: "E-post varuägare",
  COMPLAINTS_EMAIL_GREEN_CARGO: "E-post Postnord",
  COMPLAINTS_END_TEXTS_GOODS_OWNER: "Sluttexter per varuägare",
  COMPLAINTS_KPI_REPORT: "Reklamationer / KPI rapport",
  COMPLAINTS_KPI_REPORT_LAST_YEAR: "Reklamationer / KPI rapport föreg. år",
  COMPLAINTS_KPI_TEXTS: "KPI ledtexter",
  COMPLAINTS_UPDATE_KPI_STATISTICS: "Reklamationer / Uppdatera KPI Statistik",
  COMPLAINTS_MEASURE_CODES: "Åtgärdskoder",
  COMPLAINTS_REASON_CATEGORY: "Kategorier - orsakskoder",
  COMPLAINTS_REASON_CODES: "Orsakskoder",
  COMPLAINTS_REASON_CODES_API: "Orsakskoder översättning",
  COMPLAINTS_RECLAIM_GROUPS: "Reklamationsgrupper",
  COMPLAINTS_RECLAIM_LINE_STATUS: "Radstatusar",
  COMPLAINTS_RECLAIM_STATUS: "Reklamationsstatus",
  COMPLAINTS_RECLAIM_STATUS_GOODS_OWNER: "Reklamationsstatus per varuägare",
  CONFIRM_DELETION: "Bekräfta borttagning",
  CONFIRM_DELETE_ATTACHMENT: "Bekräfta borttagning av bilaga",
  CONFIRM_SENDING: "Vill du skicka detta ärende?",
  CONFIRMATION_MESSAGE: "Ditt ärende är skickat för behandling. Du kan följa ditt ärende under",
  CONFIRMATION_TITLE: "Tack, ditt ärende är nu skickat!",
  CONTACT: "Kontakt",
  COUNTRY_CODE: "Landskod",
  CONSIGNMENT_LIST: "Lista sändningar",
  CONSTANT_COLUMNS: "Konstantkolumner",
  COLUMN_SETTINGS: "Kolumninställningar",
  COOKIE_POLICY: "Cookies integritetspolicy",
  COOKIES: "Cookies",
  COUNTRY: "Land",
  COUNTRY_OF_ORIGIN: "Ursprungsland",
  CREATE_INTRASTAT_LIS: "Skapa intrastat LIS",
  CREATE_PROFORMA: "Skapa proforma",
  CURRENT_PASSWORD: "Nuvarande lösenord",
  CUSTOMS_STATE_NBR: "Tullstat nr",
  CUSTOMER_COMPLAINTS: "Reklamationer",
  CUSTOMER_ID_MISSING: "Användarens id saknas",
  CUSTOMER_LOG: "Kundlogg",
  CUSTOMER_NUMBER: "Kundnummer",
  CUSTOMER_ORDER: "Kundorder",
  CUSTOMS: "Tull",
  CUSTOMS_LIST: "Tullista",
  CUSTOMS_FILE_IMPORT: "Tullfil - import",
  CUSTOMER_ORDER_NUMBER: "Kunds ordernummer",
  CUSTOMER_INFO_MISSING: "Användarinformation saknas",
  CUSTOMER_ORDER_IMPORT: "Kundorder - import",
  CUSTOMER_ORDER_IMPORT_FILE_DESCRIPTION: "Här kan du importera kundorder genom att välja en fil att importera.",
  CUSTOMER_ORDER_REGISTER_NEW: "Kundorder - registrera ny",
  CUSTOMER_ORDER_DELETE: "Kundorder - makulera",
  CUSTOMER_TYPE_MISSING: "Användartyp saknas",
  DATA_MISSING: "Ingen data hittad",
  DATE: "Datum",
  DATE_SLASH_TIME_OF_DAY: "Datum/Klockslag",
  DROP_FILES_HERE: "Släpp filer här",
  DELETE_SUCCESSFUL: "Borttagning lyckades",
  DELIVERED: "Levererat",
  DELIVERY_ORDERS: "Leveransorder",
  DELIVERY_ORDERS_ARCHIVE: "Leveransorder - arkiv",
  DELIVERY_CREATE_RETURNS: "Retur - registrera ny",
  DELIVERY_LIST_RETURNS: "Returer - lista",
  DELIVERY_RETURNS_INTERNAL: "Returer - lista internt",
  DEPTH: "Djup",
  DELIVERY_ORDER_REPORTS: "Leveransorder / Rapporter",
  DESCRIPTION: "Beskrivning",
  DESIRED_DELIVERY_DATE: "Önskat leveransdatum",
  DESTRUCTION_WAREHOUSE: "Destruktionslager",
  DETAILS: "Detaljer",
  DEVIATION_ACTION_CODES: "Intagsavvikelse / Åtgärdskoder",
  DEVIATION_EMAIL_GOODS_OWNER: "Intagsavvikelse / E-post varuägare",
  DEVIATION_GROUPS: "Intagsavvikelse / Grupper",
  DEVIATION_POST_NORD_EMAIL: "Intagsavvikelse / E-post Postnord",
  DEVIATION_REASON_CODES: "Intagsavvikelse / Orsakskoder",
  DEVIATION_REASON_CATEGORIES: "Intagsavvikelse / Orsakskategorier",
  DEVIATION_STATUS: "Intagsavvikelse / Status",
  DEVIATIONS: "Intagsavvikelser",
  DEVIATIONS_INTERNAL: "Intagsavvikelser - intern",
  DEVIATIONS_OUTBOUND: "Leveransavvikelser",
  DIFFERENTIAL_WAREHOUSE: "Differenslager",
  EMAIL: "E-post",
  EMAIL_MISSING: "E-post saknas",
  EMAIL_OTS_LIB: "E-post",
  EMAIL_TEMPLATES: "E-post-mallar",
  EMAIL_TEST: "Email test till systemets testadress",
  EMBEDDED_PATH_INCORRECT: "Path för embedded är felaktig",
  ETA_FROM_CARRIER: "ETA från transportör",
  EVENT: "Händelse",
  EVENTS: "Händelser",
  EVENTS_ON_LINE_NBR: "Händelser - radnr",
  EXPIRY_DATE: "Bäst före",
  EXPIRED_PASSWORD_TITLE: "Ditt lösenord har gått ut och måste ändras",
  FALSE: "Nej",
  FIELD_AUDIT_TRAIL_INQUIRY: "Fältändringslogg - Frågor",
  FIELD_AUDIT_REASON_CODES: "Fältändringslogg - Orsakskoder",
  FIELD_AUDIT_TRAIL_CONTROL: "Fältändringsloggsstyrning",
  FILE_NAME: "Filnamn",
  FILES_AND_ATTACHMENTS: "Filer / Bilagor",
  FILES_OTS_LIB: "Filer (OTS Lib)",
  FIRST_NAME: "Förnamn",
  FIRST_NAME_MISSING: "Förnamn saknas",
  FOOTER_COMPANY_NAME: "PostNord TPL AB",
  FORGOT_PASSWORD_TEXT: "Ange användarnamn så skickas länk för lösenordsåterställning till användares e-postadress.",
  FORGOT_PASSWORD: "Glömt ditt lösenord",
  FROM_DATE: "Från datum",
  FROM_DELIVERY_DATE: "Från leveransdatum",
  FROM_ORDER_DATE: "Från orderdatum",
  FUNCTION_GROUPS: "Funktionsgrupper",
  FUNCTION_AUTHORIZATION: "Funktionsbehörighet",
  GDPR_CONFIRMATION_MESSAGE: "Jag har läst och godkänner innehållet i meddelandet.",
  GENERAL: "Allmänt",
  GOODS_OWNER: "Varuägare",
  GOODS_OWNER_CHANGE_WARNING:
    "Du är på väg att lämna den här sidan. Alla ändringar kommer att försvinna. Vill du byta varuägare?",
  GOODS_OWNER_REGION: "Varuägare per region",
  HEADER_TITLE: "TPL KT 2023",
  HEIGHT: "Höjd",
  HIDE: "Dölj",
  HOME: "Hem",
  IF_PAGE_NOT_OPENED_POP_UP_ERROR_EXPLAINED:
    "Om sidan inte har öppnats så kan det bero på att du har en blockering i din webbläsare som förhindrar att nya flikar öppnas.",
  INTERNET_CONNECTION_ERROR_DESCRIPTION: "Kontakten med servern bröts, kontrollera din anslutning!",
  INBOUND: "Inleveranser",
  INTERNAL_COMPLAINTS: "Reklamationer - intern",
  INTERNAL_STATUSES: "Intern status per sändningstyp",
  ITEM: "Artikel",
  ITEM_BATCH: "Artikel batch",
  ITEM_DESCRIPTION: "Artikelbeskrivning",
  ITEM_DETAILS: "Artikeldetaljer",
  ITEM_GROUP: "Artikelgrupp",
  ITEM_NUMBER: "Artikelnummer",
  ITEM_NUMBER_SHORT: "Artikelnr",
  ITEM_OVERVIEW: "Artikelöversikt",
  ITEM_QUESTION: "Artikelfråga",
  ITEM_TYPE: "Artikeltyp",
  ITEMS_REQUEST: "Artikelfråga",
  ITEMS: "Artiklar",
  ITEMS_PRELIMINARY: "Artiklar - preliminära",
  ITEM_REGISTER_NEW: "Artikel - registrera ny",
  IMPORT: "Importera",
  IMPORT_DATE: "Importdatum",
  IMPORT_HISTORY: "Importhistorik",
  IMPORT_ORDER_BH: "Import av orderfil BH",
  IMPORTED_BY: "Importerad av",
  INTEGRITY_POLICY: "Integritetspolicy",
  INBOUND_SHIPMENTS: "Inbound sändningar",
  INBOUND_ORDER_SHORTAGE_ITEMS: "Order - bristartiklar",
  INBOUND_BASIC_DATA: "Inbound / Grunddata",
  INBOUND_GENERATE_CALENDAR: "Inbound / Generera kalender",
  INSTALLED_LICENSE_OBJECTS: "Installerade licensobjekt",
  IS_EMPTY: "Är tom",
  IS_NOT_EMPTY: "Är inte tom",
  JOB_SCHEDULER: "Jobbschemaläggare",
  JOB_SCHEDULER_OTS_LIB: "Jobbschemaläggare (OTS Lib)",
  KEY_MANAGEMENT: "Nyckelhantering",
  KHG_PARAMETERS: "KHG parametrar",
  KG: "kg",
  LAST_EVENT: "Senaste händelse",
  LAST_EVENT_TIME: "Senaste händelsetid",
  LEAD_TIME_REPORTS: "Ledtidsrapporter",
  LICENSE_FILES: "Licensfiler",
  LICENSE_ACCESS: "Licenstillgång",
  LICENSE_ALLOCATION_FOR_USER_GROUP: "Licensallokering för användargrupp",
  LICENSED_USERS: "Licensierade användare",
  LICENSED_USER_FUNCTIONS: "Licensierade användarfunktioner",
  LICENSED_USER_WORKSTATIONS: "Licensierade användararbetsstationer",
  LICENSE_WORKSTATIONS: "Licensarbetsstationer",
  LICENSES: "Licenser",
  LINK: "Länk",
  LINE: "Rad",
  LINE_NBR: "Radnr",
  LOAD_CARRIER: "Lastbärare",
  LOADING_DATE: "Lastningsdatum",
  LOCALES: "Språkkonventioner",
  LOCATION: "Plats",
  LOG_OUT_BEFORE_RESET_PASSWORD: "Du måste logga ut innan du kan återställa ditt lösenord",
  LOGIN: "Logga in",
  LOGGING: "Loggning",
  LOGGER_OTS_LIB: "Logger (OTS Lib)",
  LOGOUT: "Logga ut",
  LOG_OUT_IN_PROGRESS: "Du loggas ut...",
  MANAGE_REPORTS: "Hantera rapporter",
  MANAGE_COMPLAINTS: "Hantera reklamationer",
  MENU: "Meny",
  MENU_SERVICES: "Beställ",
  MENU_REPORT_ERROR: "Felanmäl",
  MENU_ACCESS_CARD: "Passerkort",
  MENU_INFORMATION: "Information",
  MESSAGES_AND_TEMPLATES: "Meddelanden & mallar",
  MONITOR_ORDER_ITEMS: "Bevakning order / artikel",
  MEASUREMENTS_AND_WEIGHT: "Mått & vikt",
  MY_ACCOUNT: "Mitt konto",
  MY_ACCOUNT_SUBHEADER: "Hantera dina personuppgifter och säkerhetsinställningar",
  MY_QLIK_SENSE_REPORTS: "QlikSense / Mina rapporter",
  MY_REPORTS: "Mina rapporter",
  MY_REPORTS_CREATE: "Skapa rapportposter",
  MY_REPORTS_EDIT: "Mina rapporter - lägg till, uppdatera",
  MY_REPORTS_LINK: "Mina rapporter - hämta rapport",
  MY_REPORTS_READ: "Mina rapporter - visa",
  NEW_PASSWORD: "Nytt lösenord",
  NEW_ARRIVAL_REGISTRATION: "Ny ankomstregistrering",
  NO: "Nej",
  NO_ATTACHMENTS: "Inga bilagor",
  NO_CONNECTION: "Ingen anslutning",
  NO_FORM_DATA: "Ingen formulärsdata",
  NO_RESULT_FOUND: "Ingen matchande sökning",
  NO_ROWS: "Inga rader",
  NOTIFICATIONS: "Notiser",
  NUMBER_SERIES_OTS: "Nummerserier (OTS Lib)",
  OF: "av",
  OLD: "Gamla",
  ONE_TRACE: "OneTrace",
  OPEN: "Öppna",
  OPEN_AGAIN: "Öppna igen",
  OPEN_FILE_MANAGER: "Öppna filhanteraren",
  OPERATIONS: "Drift",
  ORDER: "Order",
  ORDERED: "Beställt",
  ORDER_CLIENT: "Orderägare",
  ORDER_DATE: "Orderdatum",
  ORDER_LEVEL: "Ordernivå",
  ORDER_LINES: "Orderrader",
  ORDER_NUMBER: "Ordernummer",
  ORDER_OVERVIEW: "Orderöversikt",
  ORDER_REFERENCE: "Orderreferens",
  ORDER_STATUS_10: "Preliminär",
  ORDER_STATUS_20: "Beställd",
  ORDER_STATUS_30: "Bearbetad",
  ORDER_STATUS_80: "Under behandling",
  ORDER_STATUS_90: "Klar",
  ORDER_STATUS_99: "Annullerad",
  ORDER_TIME: "Ordertid",
  ORDER_TYPE: "Ordertyp",
  OUTBOUND: "Utleveranser",
  OUTBOUND_CREATE_NEW_ORDER: "Skapa Nyutskicksorder",
  OUTBOUND_TRACK_AND_TRACE: "Utgående",
  PACKAGE: "Kolli",
  PACKAGE_LEVEL: "Kollinivå",
  PACKAGE_NUMBER: "Kollinummer",
  PACKAGE_OVERVIEW: "Kolliöversikt",
  PACKAGES: "Kolli",
  PAGE_NOT_FOUND_DESCRIPTION:
    "Sidan kunde inte hittas, kontakta supporten eller administratören vid återkommande problem",
  PAGE_NOT_FOUND: "Sidan kunde inte hittas!",
  PASSWORD: "Lösenord",
  PASSWORD_CHANGED: "Ditt lösenord har ändrats. Vänligen logga in igen.",
  PASSWORD_EXPIRED: "Lösenordet har gått ut",
  PASSWORDS_DOESNT_MATCH: "Lösenord och repetitionen av lösenord matchar inte",
  PASSWORD_HAS_TO_HAVE: "Lösenord måste innehålla:",
  PASSWORD_MISSING: "Lösenord saknas",
  PASSWORD_POLICIES: "Lösenordspolicy",
  PASSWORD_REPEAT_MISSING: "Repetetion av lösenord saknas",
  PATCH_LEVEL: "Patch level",
  PERSONAL_DATA: "Personuppgifter",
  PHONE_NO: "Telefonnr",
  PHONE_NUMBER_MISSING: "Telefonnummer saknas",
  PHONE_SHORT: "Tel",
  PLANNING_LIST: "Liggaren",
  PLANNING_LIST_EXTERNAL: "Liggaren extern",
  PURCHASE_ORDER: "Inköpsorder",
  PURCHASE_ORDER_CREATE: "Skapa order",
  PURCHASE_ORDER_IMPORT: "Inköpsorder - import",
  PURCHASE_ORDER_IMPORT_FILE_DESCRIPTION: "Här kan du importera inköpsorder genom att välja en fil att importera.",
  PURCHASE_ORDER_LINES: "Inköpsorderrader",
  PURCHASE_ORDER_OVERVIEW: "Inköpsorderöversikt",
  PURCHASE_ORDER_REQUEST: "Inköpsorderfråga",
  PURCHASE_ORDER_REGISTER_NEW: "Inköpsorder - registrera ny",
  PURCHASE_ORDER_QUESTION: "Inköpsorderfråga",
  PURCHASE_ORDERS_PRELIMINARY: "Inköpsorder - preliminära",
  PREVIOUS_PASSWORD_MISSING: "Nuvarande lösenord saknas",
  PRODUCT_GROUP: "Produktgrupp",
  PROFILE_INFORMATION_UPDATED: "Dina uppgifter har uppdaterats",
  QLIK_SENSE: "Qlik Sense",
  QUALITY_REPORTS: "Kvalitetsrapporter",
  QLIK_SENSE_USER: "Qlik Sense användare",
  QUICK_ACCESS: "Snabbåtkomst",
  QUANTITY_FROM_BATCH: "Antal från batch",
  QUARANTINE_WAREHOUSE: "Karantänlager",
  READ_MORE: "Läs mer",
  REALM: "Realm",
  RECEIVER: "Mottagare",
  RECIPIENT: "Mottagare",
  RECIPIENT_ADRESS: "Mottagarens address",
  RECIPIENT_DETAIL: "Mottagarinformation",
  RECIPIENT_EMAIL: "Mottagarens e-postadress",
  RECIPIENT_NAME: "Mottagarens namn",
  RECIPIENT_TELEPHONE_NUMBER: "Mottagarens telefonnummer",
  RECLAMATIONS: "Reklamationer",
  REDIRECT_USER: "Användare - omdirigera",
  REFERENCE_ORDER: "Referensorder",
  REGION: "Region",
  REGIONS: "Regioner",
  RELOAD: "Förnya",
  REPEAT_PASSWORD: "Repetera lösenord",
  REPORT_ERROR_TITLE: "Felanmäl",
  REPORTS: "Rapporter",
  RESET_PASSWORD_TITLE: "Ange ditt nya lösenord",
  RESET_PASSWORD: "Återställ lösenord",
  RESET: "Återställ",
  RESET_LINK_IS_SENT: "En länk för återställning av ditt lösenord har skickats till din e-post",
  RESERVATIONS_LIST: "Lista bokningar",
  RETURN_CARRIER_SERVICES: "Returer / Transporttjänster",
  RETURN_HANDLING_LOAD_CARRIER: "Returhantering - lastbärare",
  RETURN_MAIN_INFO_GOODS_OWNER: "Returer / Inställningar per varuägare",
  RETURN_PACKAGE_TYPES: "Returer / Kollislag",
  RETURN_PRINTERS: "Returer / Skrivare",
  RETURN_RECEIVER_ADDRESS: "Returer / Mottagaradresser",
  RETURN_STATUS: "Returer / Mall returstatusar",
  RETURN_TYPES: "Returer / Mall returtyper",
  RETURN_WAREHOUSE: "Returlager",
  RESERVED: "Reserverat",
  RESERVED_QUANTITY: "Reserverad kvantitet",
  REQUESTED_DELIVERY_DATE: "Önskat leveransdatum",
  ROWS: "Rader",
  SAVE: "Spara",
  SHOW_MORE: "Visa mer",
  SHOW_MORE_DETAILS: "Visa fler detaljer",
  SAVE_NEW_PASSWORD: "Spara nytt lösenord",
  SAVE_VIEW: "SPARA VY",
  SEARCH: "Sök",
  SEARCH_FOR_CASE: "Sök efter ärende",
  SEARCH_FOR_PURCHASE_ORDER_NBR_OR_ITEM_NBR: "Sök på inköpsordernr eller artikelnr",
  SEND: "Skicka",
  SERIAL_NUMBER: "Serienummer",
  SERVER_ERROR: "Serverfel",
  SERVER_ERROR_DESCRIPTION: "Servern kan inte nås - kontakta support om problemet kvarstår",
  SERVER_ERROR_UNEXPECTED_DESCRIPTION:
    "Ett oväntat fel inträffade. Försök igen och kontakta support om problemet kvarstår",
  SERVER_ERROR_DESCRIPTION_REQUIRE_LOGIN: "Servern kan inte nås - var vänlig logga in igen på nytt",
  SERVER_COULDNT_PROCESS:
    "Servern kunde inte returnera någon data, var vänlig kontakta support och meddela var problemet uppstod",
  SERVICE_TYPE: "Servicetyp",
  SERVICE_TYPE_NOT_FOUND: "Ingen servicetyp hittades",
  SERVICES_TITLE: "Beställ",
  SESSION_EXPIRED: "Din session har upphört att gälla. Du måste logga in igen för att fortsätta använda applikationen",
  SETTINGS: "Inställningar",
  SETTINGS_OTS_LIB: "Inställningar (OTS Lib)",
  SHOW_EVENTS: "Visa händelser",
  SHOW_ONLY_ADJUSTMENT_TRANSACTIONS: "Visa endast justeringstransaktioner",
  SHOW_ONLY_ITEMS_WITH_BALANCE: "Visa endast artiklar med saldo",
  SHOW_PAGE_SIZE: "Antal per sida",
  SHOW_RESULT_ON: "Visa resultat på",
  SHIPMENT: "Sändning",
  SHIPMENT_LEVEL: "Sändningsnivå",
  SHIPMENT_NUMBER: "Sändningsnummer",
  SHIPMENT_OVERVIEW: "Sändningsöversikt",
  SITE_TITLE: "TPL KT 2023",
  SMS: "SMS",
  SMS_TEMPLATES: "SMS-mallar",
  STADIUM_PRICE_LABELING: "Prismärkning - Stadium",
  STATISTICS: "Statistik",
  STATUS: "Status",
  STATUS_COMMENT: "Statuskommentar",
  STATUS_CODE: "Statuskod",
  STATUS_CODE_COLOR: "Händelsefärger",
  STATUS_TRANSLATION: "Statusöversättning",
  SUB_NAV_MY_ACCOUNT: "Mitt konto",
  SUB_NAV_MY_CASES: "Mina ärenden",
  SUB_NAV_MY_CASES_INFO: "Detaljer",
  SUB_NAV_MY_CASES_FORM_DATA: "Formulärsdata",
  SUB_NAV_MY_CASES_ATTACHMENTS: "Bilagor",
  SUB_NAV_MY_PERSONAL_DATA: "Personlig information",
  SUB_NAV_MY_PASSWORD: "Lösenord",
  SUPPLIER: "Leverantör",
  SUPPLEMENT: "Bilaga",
  SUPPORT: "Support",
  SURNAME: "Efternamn",
  SURNAME_MISSING: "Efternamn saknas",
  SYSTEM_ADMINISTRATION: "Systemadministration",
  SYSTEM_CONTROL: "Systemkontroll",
  SYSTEM_STATISTICS: "Statistik",
  SYSTEM_TOOLS: "Systemverktyg",
  TIME: "Tid",
  TO_DATE: "Till datum",
  TO_DELIVERY_DATE: "Till leveransdatum",
  TO_ORDER_DATE: "Till orderdatum",
  TOKEN_MISSING: "Token saknas",
  INVENTORY_TRANSACTIONS: "Lagertransaktioner",
  TRACK_AND_TRACE: "Track & Trace",
  TRACK_AND_TRACE_DESCRIPTION: "Följ dina kundorder",
  TRACK_AND_TRACE_PAGE_SEARCH: "Sök på ordernr, sändningsnr eller kollinr",
  TRACK_AND_TRACE_STATISTICS: "Track & Trace / Statistik",
  TRACK_PACKAGE_WITH_CARRIER: "Spåra kolli hos transportör",
  TRANSACTION: "Transaktion",
  TRANSACTION_CODE: "Transaktionskod",
  TRANSACTIONS: "Transaktioner",
  TRUE: "Ja",
  TRYING_TO_RECONNECT: "Försöker återuppta kontakten med servern...",
  TYPE: "Type",
  UNABLE_TO_LOG_OUT: "Tyvärr kunde vi inte logga ut dig, var vänlig och försök igen",
  UNIT: "Enhet",
  UNLOADING_REPORTS_SHOW: "Lossningsrapporter - visa",
  UPLOAD_DOCUMENT: "Bifoga dokument",
  UPLOAD_SUCCESSFUL: "Uppladdning lyckades",
  USER: "Användare",
  USER_IS_NOT_LOGGED_IN: "Användaren är inte inloggad",
  USER_MESSAGE_NOT_ACCEPTED: "Villkoren måsta accepteras för att använda tjänsten",
  USERNAME: "Användarnamn",
  USERNAME_MISSING: "Användarnamn saknas",
  USER_ACCESS_MESSAGES: "Användarmeddelanden",
  USER_ACCESS_MESSAGES_EXCEPTIONS: "Användaråtkomstmeddelanden - undantag",
  USER_ACCESS_MESSAGES_READ_LOG: "Användaråtkomstmeddelanden - Läslogg",
  USER_GROUPS: "Användargrupper",
  USER_LOGIN_HISTORY: "Användarloginhistorik",
  USER_MESSAGES: "Användarmeddelanden",
  USER_ROLES: "Användarroller",
  USER_SESSIONS: "Användarsessioner",
  USER_TASK_LOG: "Användarfunktionslogg",
  USERS: "Användare",
  USERS_CONTROL_TOWER: "Användare Kontrolltornet",
  USERS_GROUPS_AND_ROLES: "Användare, grupper & roller",
  VERSION: "Version",
  VIEW_PACKAGE: "Visa kolli",
  VIRTUAL_SESSIONS: "Virtuella sessioner",
  VOLUME: "Volym",
  VOLUME_STATISTICS: "Volymstatistik",
  WAREHOUSE: "Lager",
  WAREHOUSE_TRANSACTIONS: "Lagertransaktioner",
  WEB_AND_REST_SERVICES: "Web & REST Services",
  WEIGHT: "Vikt",
  WELCOME_TEXT: "",
  WELCOME_TITLE: "Välkommen till Kontrolltornet",
  WEEK_LETTER_SUPPORT: "Underhåll av anslagstavla",
  WIDTH: "Bredd",
  WORK_WITH_PROFORMA: "Arbeta med proforma",
  WRONG_USERNAME_OR_PASSWORD: "Fel användarnamn eller lösenord",
  YES: "Ja",
  YOUR_CASES: "dina ärenden",
  YOUR_LINK_OPENED_IN_NEW_TAB: "Din länk har öppnats i en ny flik i webbläsaren",
  ZETES_IMAGE_LINK: "Zetes-länk",
  ZIP_CODE: "Postnummer",
};

export default sv;
