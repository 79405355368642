import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { Except } from "type-fest";
import { ITEM_FIELDS } from "../../../features/item/dataTypes";
import useTranslate from "../../../language/useTranslate";
import { COLUMN_TYPES } from "../../DataGrid/column/columnTypes";

const STANDARD_COLUMN_DEF: Readonly<Except<GridColDef, "type" | "field">> = {
  editable: false,
  flex: 1,
};

export default function useItemPromptColumns(): GridColDef[] {
  const translate = useTranslate();

  return useMemo(
    () => [
      {
        ...STANDARD_COLUMN_DEF,
        field: ITEM_FIELDS.CLIENT_ITEM_NUMBER,
        headerName: translate("ITEM_NUMBER"),
        type: COLUMN_TYPES.STRING,
      },
      {
        ...STANDARD_COLUMN_DEF,
        field: ITEM_FIELDS.DESCRIPTION,
        headerName: translate("DESCRIPTION"),
        headerAlign: "left",
      },
    ],
    [translate],
  );
}
