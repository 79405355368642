import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const itemBatchSliceName = "itemBatch";

export interface ResponseItemBatch extends IdObject {
  goodsOwnerId: string;
  itemNumber: string;
  itemId: string;
  batch: string;
  container: string;
  balance: number;
  reservedQuantity: number;
  availableQuantity: number;
  expirationDate: number;
  status: string;
  isAvailable: boolean;
  blockInformation: string;
  type: string;
}

export interface GetItemBatchesFilter extends ClientPaginatedRequestFilter {}

export interface ItemBatch extends ResponseItemBatch, AdditionalRequestState {}

export const ITEM_BATCH_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponseItemBatch, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  GOODS_OWNER_ID: "goodsOwnerId",
  ITEM_NUMBER: "itemNumber",
  ITEM_ID: "itemId",
  BATCH: "batch",
  CONTAINER: "container",
  BALANCE: "balance",
  RESERVED_QUANTITY: "reservedQuantity",
  AVAILABLE_QUANTITY: "availableQuantity",
  EXPIRATION_DATE: "expirationDate",
  STATUS: "status",
  IS_AVAILABLE: "isAvailable",
  BLOCK_INFORMATION: "blockInformation",
  TYPE: "type",
};
