import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { FILE_FIELDS } from "../file/dataTypes";
import { ClientPaginatedRequestFilter, GoodsOwnerPaginatedRequestFilter } from "../utils/pagination";

export const purchaseOrderSliceName = "purchaseOrder";

export interface GetFilesUploadedToWMSFilter extends GoodsOwnerPaginatedRequestFilter {}

export interface UploadFilesToWMSRequest {
  goodsOwner: string;
}

export const GET_FILES_UPLOADED_TO_WMS_SEARCH_FIELDS = {
  ...FILE_FIELDS,
  GOODS_OWNER: "goodsOwner",
};

export interface ResponsePurchaseOrder extends IdObject {
  client: string;
  orderNumber: string;
  purchaseOrderNbr: string;
  orderType: string;
  deliveryDate: number;
  orderDate: number;
  supplier: string;
  status: string;
  statusColor: string;
}

export interface GetPurchaseOrdersFilter extends ClientPaginatedRequestFilter {}

export interface PurchaseOrder extends ResponsePurchaseOrder, AdditionalRequestState {}

export const PURCHASE_ORDER_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponsePurchaseOrder, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  CLIENT: "client",
  ORDER_NUMBER: "orderNumber",
  PURCHASE_ORDER_NBR: "purchaseOrderNbr",
  ORDER_TYPE: "orderType",
  DELIVERY_DATE: "deliveryDate",
  ORDER_DATE: "orderDate",
  SUPPLIER: "supplier",
  STATUS: "status",
  STATUS_COLOR: "statusColor",
};

export const PURCHASE_ORDER_FIELDS_FILTRATION_FIELDS = {
  ...PURCHASE_ORDER_FIELDS,
  SEARCH: "search",
  ITEM_NUMBER: "itemNumber",
};
