import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button, styled } from "@mui/material";
import { ComponentPropsWithoutRef } from "react";
import { MutableOnlyArray } from "../../../../utils/dataTypes";
import { ButtonProps, SXProps } from "./dataTypes";

interface CustomButtonProps extends Omit<ButtonProps<"label">, "onChange" | "role" | "tabIndex" | "startIcon"> {
  isDirectUpload?: boolean;
  onAttach?: ComponentPropsWithoutRef<"input">["onChange"];
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AttachFileButton({ label, isDirectUpload, sx: sxProp, onAttach, ...rest }: CustomButtonProps) {
  const sx: MutableOnlyArray<SXProps> = [{ borderRadius: isDirectUpload ? "3000px" : undefined }];

  if (Array.isArray(sxProp)) sx.push(...sxProp);
  else if (sxProp) sx.push(sxProp);

  return (
    <Button
      {...rest}
      component="label"
      role={undefined}
      tabIndex={-1}
      startIcon={isDirectUpload ? <AddIcon /> : <AttachFileIcon />}
      sx={sx}
    >
      {label}
      <VisuallyHiddenInput type="file" onChange={onAttach} multiple />
    </Button>
  );
}
