import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { itemSliceName } from "./dataTypes";
import { itemAdapter } from "./itemAdapter";
import { addFilesReducers } from "./reducers/addFiles";
import { addDeleteFileReducers } from "./reducers/deleteFile";
import { addFetchFilesReducers } from "./reducers/fetchFiles";
import { addFetchItemReducers } from "./reducers/fetchItem";
import { addFetchItemsReducers } from "./reducers/fetchItems";

const slice = createSlice({
  name: itemSliceName,
  initialState: itemAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchItemReducers(builder);
    addFetchItemsReducers(builder);
    addFetchFilesReducers(builder);
    addFilesReducers(builder);
    addDeleteFileReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
