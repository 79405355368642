import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { GetItemFilesFilter, Item, itemSliceName } from "../dataTypes";
import { itemAdapter } from "../itemAdapter";

async function doFetchFiles(
  dispatch: AppDispatch,
  { itemId, filter }: { itemId: string; filter: Readonly<GetItemFilesFilter> },
) {
  const request = new Request(api.item.getFiles(itemId), Method.POST);

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch }));
}

interface Response {
  files: PaginationResponse<ResponseFile>;
}

export const fetchFiles = createAsyncThunk<
  Response,
  { itemId: string; filter: Readonly<GetItemFilesFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(itemSliceName + "/getFiles", doFetchFiles);

export const addFetchFilesReducers = (builder: EntityActionReducerBuilderInterface<Item>) => {
  builder.addCase(fetchFiles.pending, (state, action) => {
    const {
      meta: {
        arg: { itemId },
      },
    } = action;

    const item = state.entities[itemId] ?? ({ id: itemId } as Item);

    if (!item.files) {
      item.files = { ids: [], count: 0 };
    }

    item.files.errorMessages = undefined;
    item.files.requestState = REQUEST_STATE.PENDING;

    itemAdapter.upsertOne(state, item);
  });
  builder.addCase(fetchFiles.fulfilled, (state, action) => {
    const itemId = action.meta.arg.itemId;
    const item = state.entities[itemId];

    if (item?.files == null) return;

    const fulfilledUpdateMethod = action.meta.arg.fulfilledUpdateMethod;
    const payload = action.payload;
    const files = item.files;
    const ids = fulfilledUpdateMethod === FULFILLED_UPDATE_METHOD.SET_ALL ? [] : files.ids;
    const { count, page } = payload.files;

    for (const { id } of page) {
      if (!ids.includes(id)) {
        ids.push(id);
      }
    }

    files.ids = ids;
    files.count = count;

    files.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchFiles.rejected, (state, action) => {
    const item = state.entities[action.meta.arg.itemId];
    const files = item?.files;

    if (files == null) return;

    files.requestState = REQUEST_STATE.REJECTED;
    files.errorMessages = action.payload?.errors;
  });
};
