import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { MergeExclusive } from "type-fest";
import { File as FileBE } from "../../../../features/file/dataTypes";
import useTranslate from "../../../../language/useTranslate";
import { DATE_FORMATS } from "../../../../utils/DateUtil";
import AttachFileButton from "../../PostNord/Button/AttachFileButton";

type FileListProps = {
  files: FileBE[];
  isLoading?: boolean;
} & MergeExclusive<
  {
    hasAddAttachment: true;
    onAddAttachment: (files: File[]) => void;
  },
  {
    hasAddAttachment?: false;
  }
> &
  MergeExclusive<
    {
      hasDelete: true;
      onDelete: (file: FileBE) => void;
    },
    {
      hasDelete?: false;
    }
  >;

type FileRowProps = {
  row: FileBE;
} & MergeExclusive<
  {
    hasDelete: true;
    onDelete: (file: FileBE) => void;
  },
  {
    hasDelete?: false;
  }
>;

const FileList = ({
  files,
  isLoading = false,
  hasDelete,
  onDelete,
  hasAddAttachment,
  onAddAttachment,
}: FileListProps) => {
  const translate = useTranslate();

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: "1px solid #00000033",
        minHeight: "10rem",
      }}
    >
      <Table aria-label="simple table">
        <TableBody>
          {isLoading ? (
            <TableCell align="center" sx={{ border: "none" }}>
              <CircularProgress />
            </TableCell>
          ) : files.length === 0 ? (
            <TableCell component="th" scope="row" align="center" sx={{ border: "none" }}>
              {hasAddAttachment && <AddAttachmentButton onClick={onAddAttachment} />}
              <Typography padding={"2.2rem"}>{translate("NO_ROWS")}</Typography>
            </TableCell>
          ) : (
            <>
              {hasAddAttachment && <AddAttachmentButton onClick={onAddAttachment} />}

              {files.map(row =>
                hasDelete ? (
                  <FileRow key={row.id} row={row} hasDelete={true} onDelete={onDelete} />
                ) : (
                  <FileRow key={row.id} row={row} />
                ),
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FileRow = ({ row, hasDelete, onDelete }: FileRowProps) => {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          border: "none",
        },
      }}
    >
      <TableCell component="th" scope="row">
        <Box display={"flex"} flexDirection={"row"} gap={"12rem"}>
          <RowLink row={row} />
          {hasDelete && <DeleteButton row={row} onClick={onDelete} />}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const RowLink = ({ row }: { row: FileBE }) => {
  return (
    <Link
      href={row.url}
      display="flex"
      alignItems="center"
      underline="none"
      gap={"3.2rem"}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        "&:hover": {
          "& .underline-on-hover": {
            textDecoration: "underline",
          },
        },
      }}
    >
      <InsertDriveFileIcon />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography className="underline-on-hover">{row.name}</Typography>
        <Box display={"flex"} gap={"2.4rem"}>
          <Typography color="textSecondary" variant="caption">
            {dayjs(row.createdTimestamp).format(DATE_FORMATS.YYYY_MM_DD_HH_mm)}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {row.createdBy}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

const AddAttachmentButton = ({ onClick }: { onClick: (files: File[]) => void }) => {
  const translate = useTranslate();

  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          border: "none",
        },
      }}
    >
      <TableCell component="th" scope="row">
        <AttachFileButton
          label={translate("ADD_ATTACHMENTS")}
          variant="outlined"
          isDirectUpload
          onAttach={e => {
            if (!e.target.files) return;

            onClick([...e.target.files]);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const DeleteButton = ({ row, onClick }: { row: FileBE; onClick: (file: FileBE) => void }) => {
  return (
    <Link
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      marginLeft={"50%"}
      component="button"
      variant="body2"
      onClick={() => {
        onClick(row);
      }}
    >
      <DeleteOutlineOutlinedIcon />
    </Link>
  );
};

export default FileList;
