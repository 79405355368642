import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { deleteFile } from "../../item/reducers/deleteFile";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { File } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addDeleteFileForItemReducers = (builder: EntityActionReducerBuilderInterface<File>) => {
  builder.addCase(deleteFile.pending, (state, action) => {
    const { fileId } = action.meta.arg;
    const file = state.entities[fileId] ?? ({ id: fileId } as File);

    fileAdapter.upsertOne(state, {
      ...file,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(deleteFile.fulfilled, (state, action) => {
    const { fileId } = action.meta.arg;

    fileAdapter.removeOne(state, fileId);
  });
  builder.addCase(deleteFile.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { fileId } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      fileAdapter.removeOne(state, fileId);
    } else {
      fileAdapter.updateOne(state, {
        id: fileId,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
