import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { Item, itemSliceName, ResponseItem } from "../dataTypes";
import { itemAdapter } from "../itemAdapter";
import mapFulfilledItemResponse from "../utils/mapFulfilledItemResponse";

const getItem = async (dispatch: AppDispatch, { id }: { id: string }) => {
  const url = api.item.getItem(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  item: ResponseItem;
}

export const fetchItem = createAsyncThunk<Response, { id: string }>(itemSliceName + "/fetchItem", getItem);

export const addFetchItemReducers = (builder: EntityActionReducerBuilderInterface<Item>) => {
  builder.addCase(fetchItem.pending, (state, action) => {
    const { id } = action.meta.arg;
    const item = state.entities[id] ?? ({ id } as Item);

    itemAdapter.upsertOne(state, {
      ...item,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchItem.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    const currentItem = state.entities[id];

    itemAdapter.updateOne(state, {
      id,
      changes: mapFulfilledItemResponse({
        responseItem: payload.item,
        currentItem,
      }),
    });
  });
  builder.addCase(fetchItem.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      itemAdapter.removeOne(state, id);
    } else {
      itemAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
