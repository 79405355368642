import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetItemsFilter } from "../dataTypes";
import useLazyGetItems from "./useLazyGetItems";

const useGetItems = (args: {
  filter: Readonly<GetItemsFilter> | undefined;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
}) => {
  const { isLoading: isLazyLoading, fetchItems, ...rest } = useLazyGetItems(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    const initiateItems = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchItems();
      initiationStatus.current = "initiated";
    };

    initiateItems();
  }, [fetchItems, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchItems: fetchItems };
};

export default useGetItems;
