import en from "../en";

const da: typeof en.translation = {
  ACCESS_CARD_TITLE: "Adgangskort",
  ADD_ATTACHMENTS: "Tilføj vedhæftninger",
  ADDRESS: "Adresse",
  ADJUSTMENT_ORDER_ADJUSTMENT_CODES: "Nyudsendelsesordre / Justeringskoder",
  ADJUSTMENT_ORDER_ADJUSTMENT_ORDER_TYPE: "Nyudsendelsesordre / Ordretyper justering",
  ADMINISTRATE_USERS: "Administrer brugere",
  ADMINISTRATION: "Administration",
  ALERT_ERROR_TITLE: "Fejl",
  ALERT_INFO_TITLE: "Info",
  ALERT_SUCCESS_TITLE: "Succes",
  ALERT_WARNING_TITLE: "Advarsel",
  ALREADY_LOGGED_IN: "Du er allerede logget ind",
  ADVANCED_AUTHENTICATION: "Avanceret godkendelse",
  ALL: "Alle",
  AMOUNT: "Beløb",
  AMOUNT_OF_PACKAGES: "Antal kolli",
  APPLICATION_ERROR_LOG: "Applikationsfejl log",
  ARRIVAL_REGISTRATIONS: "Ankomstregistreringer",
  ARRIVAL_REGISTRATION_MY_PRINTER: "Ankomstregistrering Min printer",
  AUTHORIZATION: "Tilladelse",
  AVAILABLE: "Tilgængelig",
  APPROVE: "Godkend",
  ATTACH: "Vedhæft",
  ATTACHMENTS: "Vedhæftninger",
  BACK: "Tilbage",
  BACK_TO_START: "Tilbage til Start",
  BALANCE: "Balance",
  BALANCE_INFORMATION: "Balance information",
  BAR_CODE: "Stregkode",
  BATCH_NUMBER_MANAGEMENT: "Batchnummerstyring",
  BATCH: "Batch",
  BEST_BEFORE: "Bedst før",
  BLOCK_INFO: "Blokeringsinformation",
  BLOCKING_WAREHOUSE: "Blokeringslager",
  BOOKING_SHOW: "Booking - vis",
  BOOKING_UPDATE: "Booking - opdater",
  CA_USER: "Administrer brugere - admin",
  CA_CHANGE_PASSWORD: "Bruger - skift kodeord",
  CA_GOODS_OWNER: "Administrer vareejer - admin",
  CANCEL: "Annuller",
  CANNOT_SHOW_IMAGE_OF_PACKAGE: "Kan ikke vise billede af kolli",
  CARRIER: "Transportør",
  CARRIER_EVENTS: "Transportørhændelser",
  CARRIER_DETAILS: "Transportøroplysninger",
  CARRIER_PRODUCT: "Transportørprodukt",
  CARRIER_STATUS: "Transportørstatus",
  CASE: "Sag",
  CASE_NOT_FOUND: "Sagen kunne ikke findes",
  CHANGE_GOODS_OWNER: "Skift vareejer",
  CHANGE_PASSWORD: "Skift kodeord",
  CHANGE_LOG: "Ændringslog",
  CHOOSE_FILE: "Vælg fil",
  CHOOSE_GOODS_OWNER: "Vælg vareejer",
  CITY: "By",
  CLEAR: "Ryd",
  CLICK_LINK_OPEN_AGAIN: "Klik på linket for at åbne siden igen",
  CLIENT_TRANSACTION_CODES: "Klientens transaktionskoder",
  CLOSE: "Luk",
  CLOSE_THIS_TAB: "Luk denne fane",
  CLOSED: "Lukkede",
  CM: "cm",
  COMFLOW_SYSTEM_SETTINGS: "CF-Systemindstillinger",
  COMFLOW_USER_SESSIONS: "CF-Brugersessioner",
  COMFLOW_CHANGE_GOODS_OWNER: "Skift vareejer",
  COMPLAINTS_INTERNAL: "Reklamationer - intern",
  COMPLAINTS: "Reklamationer",
  COMPLAINTS_API: "Reklamationer API",
  COMPLAINTS_ATTACHMENT_TYPES: "Tilladte vedhæftningstyper",
  COMPLAINTS_DAMAGE_CODES: "Skadekoder",
  COMPLAINTS_EMAIL_BUYER: "E-mail vareejer",
  COMPLAINTS_EMAIL_GREEN_CARGO: "E-mail Postnord",
  COMPLAINTS_KPI_REPORT: "Reklamationer / KPI-rapport",
  COMPLAINTS_KPI_REPORT_LAST_YEAR: "Reklamationer / KPI-rapport forrige år",
  COMPLAINTS_END_TEXTS_GOODS_OWNER: "Sluttekster pr. vareejer",
  COMPLAINTS_KPI_TEXTS: "KPI tekster",
  COMPLAINTS_UPDATE_KPI_STATISTICS: "Reklamationer / Opdater KPI-statistik",
  COMPLAINTS_MEASURE_CODES: "Foranstaltningskoder",
  COMPLAINTS_REASON_CATEGORY: "Kategorier - årsagskoder",
  COMPLAINTS_REASON_CODES: "Årsagskoder",
  COMPLAINTS_REASON_CODES_API: "Årsagskoder oversættelse",
  COMPLAINTS_RECLAIM_GROUPS: "Reklamationsgrupper",
  COMPLAINTS_RECLAIM_LINE_STATUS: "Linjestatusser",
  COMPLAINTS_RECLAIM_STATUS: "Reklamationsstatus",
  COMPLAINTS_RECLAIM_STATUS_GOODS_OWNER: "Reklamationsstatus pr. vareejer",
  CONFIRM_DELETION: "Bekræft sletning",
  CONFIRM_DELETE_ATTACHMENT: "Bekræft sletning af vedhæftning",
  CONFIRM_SENDING: "Vil du sende denne sag?",
  CONFIRMATION_MESSAGE: "Din sag er sendt til behandling. Du kan følge din sag under",
  CONFIRMATION_TITLE: "Tak, din sag er nu sendt!",
  CONSIGNMENT_LIST: "Liste over forsendelser",
  CONTACT: "Kontakt",
  COLUMN_SETTINGS: "Kolonneindstillinger",
  COOKIE_POLICY: "Cookies privatlivspolitik",
  COOKIES: "Cookies",
  CONSTANT_COLUMNS: "Konstante kolonner",
  COUNTRY: "Land",
  COUNTRY_CODE: "Landskode",
  COUNTRY_OF_ORIGIN: "Oprindelsesland",
  CREATE_INTRASTAT_LIS: "Opret intrastat LIS",
  CREATE_PROFORMA: "Opret proforma",
  CURRENT_PASSWORD: "Nuværende kodeord",
  CUSTOMER_COMPLAINTS: "Reklamationer",
  CUSTOMER_ID_MISSING: "Bruger-id mangler",
  CUSTOMER_INFO_MISSING: "Brugeroplysninger mangler",
  CUSTOMER_LOG: "Kundelogg",
  CUSTOMER_NUMBER: "Kundnummer",
  CUSTOMER_ORDER: "Kundeordre",
  CUSTOMER_ORDER_REGISTER_NEW: "Kundeordre - registrer ny",
  CUSTOMER_ORDER_DELETE: "Kundeordre - annuller",
  CUSTOMER_ORDER_IMPORT: "Kundeordre - import",
  CUSTOMER_ORDER_IMPORT_FILE_DESCRIPTION:
    "Her kan du importere en kundeordre ved at vælge en fil, der skal importeres.",
  CUSTOMER_ORDER_NUMBER: "Kundens ordrenummer",
  CUSTOMER_TYPE_MISSING: "Brugertype mangler",
  CUSTOMS: "Told",
  CUSTOMS_LIST: "Toldliste",
  CUSTOMS_FILE_IMPORT: "Toldfil - import",
  CUSTOMS_STATE_NBR: "Toldstatusnummer",
  DATA_MISSING: "Ingen data fundet",
  DATE: "Dato",
  DATE_SLASH_TIME_OF_DAY: "Dato/Tidspunkt",
  DELETE_SUCCESSFUL: "Sletning vellykket",
  DELIVERED: "Leveret",
  DELIVERY_ORDERS: "Leveringsordre",
  DELIVERY_ORDERS_ARCHIVE: "Leveringsordre - arkiv",
  DELIVERY_CREATE_RETURNS: "Retur - registrer ny",
  DELIVERY_LIST_RETURNS: "Retur - liste",
  DELIVERY_RETURNS_INTERNAL: "Retur - intern liste",
  DEPTH: "Dybde",
  DESCRIPTION: "Beskrivelse",
  DETAILS: "Detaljer",
  DESIRED_DELIVERY_DATE: "Ønsket leveringsdato",
  DESTRUCTION_WAREHOUSE: "Destruktionslager",
  DEVIATION_ACTION_CODES: "Afvigelsesindtag / Foranstaltningskoder",
  DEVIATION_EMAIL_GOODS_OWNER: "Afvigelsesindtag / E-mail vareejer",
  DEVIATION_GROUPS: "Afvigelsesindtag / Grupper",
  DEVIATION_POST_NORD_EMAIL: "Afvigelsesindtag / E-mail Postnord",
  DELIVERY_ORDER_REPORTS: "Leveringsordre / Rapporter",
  DEVIATION_REASON_CATEGORIES: "Afvigelsesindtag / Årsagskategorier",
  DEVIATION_REASON_CODES: "Afvigelsesindtag / Årsagskoder",
  DEVIATION_STATUS: "Afvigelsesindtag / Status",
  DEVIATIONS: "Afvigelser",
  DEVIATIONS_INTERNAL: "Afvigelser - interne",
  DEVIATIONS_OUTBOUND: "Leveringsafvigelser",
  DIFFERENTIAL_WAREHOUSE: "Differentiallager",
  DROP_FILES_HERE: "Slip filer her",
  EMAIL: "E-mail",
  EMAIL_MISSING: "E-mail mangler",
  EMAIL_OTS_LIB: "E-mail",
  EMAIL_TEMPLATES: "E-mail-skabeloner",
  EMAIL_TEST: "E-mail test til systemets testadresse",
  EMBEDDED_PATH_INCORRECT: "Path for embedded er forkert",
  ETA_FROM_CARRIER: "ETA fra transportør",
  EVENT: "Begivenhed",
  EVENTS: "Begivenheder",
  EVENTS_ON_LINE_NBR: "Begivenheder - linje nr.",
  EXPIRY_DATE: "Udløbsdato",
  EXPIRED_PASSWORD_TITLE: "Din adgangskode er udløbet og skal ændres",
  FALSE: "Nej",
  FILE_NAME: "Filnavn",
  FILES_OTS_LIB: "Filer (OTS Lib)",
  FIELD_AUDIT_TRAIL_INQUIRY: "Feltændringslog - Forespørgsler",
  FIELD_AUDIT_REASON_CODES: "Feltændringslog - Årsagskoder",
  FIELD_AUDIT_TRAIL_CONTROL: "Feltændringslog kontrol",
  FILES_AND_ATTACHMENTS: "Filer / Vedhæftninger",
  FIRST_NAME: "Fornavn",
  FIRST_NAME_MISSING: "Fornavn mangler",
  FOOTER_COMPANY_NAME: "PostNord TPL AB",
  FORGOT_PASSWORD_TEXT:
    "Indtast brugernavn, og der sendes et link til nulstilling af adgangskode til brugerens e-mailadresse.",
  FORGOT_PASSWORD: "Glemt dit kodeord",
  FROM_DATE: "Fra dato",
  FROM_DELIVERY_DATE: "Fra leveringsdato",
  FROM_ORDER_DATE: "Fra ordredato",
  FUNCTION_GROUPS: "Funktionsgrupper",
  FUNCTION_AUTHORIZATION: "Funktionsgodkendelse",
  GDPR_CONFIRMATION_MESSAGE: "Jeg har læst og godkender indholdet af beskeden.",
  GENERAL: "Generelt",
  GOODS_OWNER: "Vareejer",
  GOODS_OWNER_CHANGE_WARNING: "Du er ved at forlade denne side. Alle ændringer vil gå tabt. Vil du skifte vareejer?",
  GOODS_OWNER_REGION: "Vareejer pr. region",
  HEADER_TITLE: "TPL KT 2023",
  HEIGHT: "Højde",
  HIDE: "Skjul",
  HOME: "Hjem",
  IF_PAGE_NOT_OPENED_POP_UP_ERROR_EXPLAINED:
    "Hvis siden ikke åbnede, kan du have en pop-up blocker i din webbrowser, der forhindrer nye faner i at blive åbnet.",
  INTERNET_CONNECTION_ERROR_DESCRIPTION: "Forbindelsen til serveren blev afbrudt, tjek din forbindelse!",
  INBOUND_SHIPMENTS: "Indgående forsendelser",
  INTEGRITY_POLICY: "Integritetspolitik",
  INTERNAL_COMPLAINTS: "Interne reklamationer",
  INTERNAL_STATUSES: "Intern status pr. forsendelsestype",
  IMPORT: "Import",
  IMPORT_DATE: "Importdato",
  IMPORT_HISTORY: "Importhistorik",
  IMPORT_ORDER_BH: "Importordre BH",
  IMPORTED_BY: "Importeret af",
  ITEM: "Vare",
  ITEM_BATCH: "Varebatch",
  ITEM_DESCRIPTION: "Varebeskrivelse",
  ITEM_DETAILS: "Varedetaljer",
  ITEM_GROUP: "Varegruppe",
  ITEM_NUMBER: "Varenummer",
  ITEM_NUMBER_SHORT: "Vare nr.",
  ITEM_OVERVIEW: "Vareoversigt",
  ITEM_QUESTION: "Varespørgsmål",
  ITEM_REGISTER_NEW: "Vare - registrer ny",
  ITEMS: "Varer",
  ITEM_TYPE: "Varetype",
  ITEMS_PRELIMINARY: "Varer - foreløbige",
  ITEMS_REQUEST: "Vareanmodning",
  INBOUND: "Indgående",
  INBOUND_ORDER_SHORTAGE_ITEMS: "Ordre - mangelvarer",
  INBOUND_BASIC_DATA: "Indgående / Grunddata",
  INBOUND_GENERATE_CALENDAR: "Indgående / Generer kalender",
  INSTALLED_LICENSE_OBJECTS: "Installerede licensobjekter",
  INVENTORY_TRANSACTIONS: "Lagertransaktioner",
  IS_EMPTY: "Er tom",
  IS_NOT_EMPTY: "Er ikke tom",
  JOB_SCHEDULER: "Jobplanlægger",
  JOB_SCHEDULER_OTS_LIB: "Jobplanlægger (OTS Lib)",
  KEY_MANAGEMENT: "Nøglehåndtering",
  KG: "kg",
  KHG_PARAMETERS: "KHG parametre",
  LAST_EVENT: "Sidste begivenhed",
  LAST_EVENT_TIME: "Tidspunkt for sidste begivenhed",
  LEAD_TIME_REPORTS: "Lead time rapporter",
  LICENSE_FILES: "Licensfiler",
  LICENSE_ACCESS: "Licensadgang",
  LICENSE_ALLOCATION_FOR_USER_GROUP: "Licensallokering for brugergruppe",
  LICENSED_USERS: "Licenserede brugere",
  LICENSED_USER_FUNCTIONS: "Licenserede brugerfunktioner",
  LICENSED_USER_WORKSTATIONS: "Licenserede arbejdsstationer",
  LICENSE_WORKSTATIONS: "Licensarbejdsstationer",
  LICENSES: "Licenser",
  LINE: "Linje",
  LINE_NBR: "Linje nr.",
  LINK: "Link",
  LOAD_CARRIER: "Lastbærer",
  LOADING_DATE: "Lastedato",
  LOCALES: "Lokaler",
  LOCATION: "Placering",
  LOG_OUT_BEFORE_RESET_PASSWORD: "Du skal logge ud, før du nulstiller din adgangskode",
  LOG_OUT_IN_PROGRESS: "Du bliver logget ud...",
  LOGIN: "Log ind",
  LOGGING: "Logning",
  LOGGER_OTS_LIB: "Logger (OTS Lib)",
  LOGOUT: "Log ud",
  MENU: "Menu",
  MANAGE_REPORTS: "Administrer rapporter",
  MANAGE_COMPLAINTS: "Administrer reklamationer",
  MEASUREMENTS_AND_WEIGHT: "Målinger og vægt",
  MENU_SERVICES: "Ordre",
  MENU_REPORT_ERROR: "Fejlrapport",
  MENU_ACCESS_CARD: "Adgangskort",
  MENU_INFORMATION: "Information",
  MESSAGES_AND_TEMPLATES: "Beskeder & skabeloner",
  MONITOR_ORDER_ITEMS: "Overvåg ordre / vare",
  MY_ACCOUNT: "Min konto",
  MY_ACCOUNT_SUBHEADER: "Håndter dine oplysninger og sikkerhedsindstillinger",
  MY_QLIK_SENSE_REPORTS: "QlikSense / Mine rapporter",
  MY_REPORTS: "Mine rapporter",
  MY_REPORTS_CREATE: "Opret rapportposter",
  MY_REPORTS_EDIT: "Mine rapporter - tilføj, opdater",
  MY_REPORTS_LINK: "Mine rapporter - download rapport",
  MY_REPORTS_READ: "Mine rapporter - vis",
  NEW_PASSWORD: "Nyt kodeord",
  NEW_ARRIVAL_REGISTRATION: "Ny ankomstregistrering",
  NO: "Nej",
  NO_ATTACHMENTS: "Ingen vedhæftninger",
  NO_CONNECTION: "Ingen forbindelse",
  NO_FORM_DATA: "Ingen formular data fundet",
  NO_RESULT_FOUND: "Intet resultat fundet",
  NO_ROWS: "Ingen rækker",
  NOTIFICATIONS: "Notifikationer",
  NUMBER_SERIES_OTS: "Nummer serier (OTS Lib)",
  OF: "af",
  OLD: "Gammel",
  ONE_TRACE: "OneTrace",
  OPEN: "Åben",
  OPEN_AGAIN: "Åben igen",
  OPEN_FILE_MANAGER: "Åben filhåndtering",
  OPERATIONS: "Operationer",
  ORDER: "Ordre",
  ORDERED: "Bestilt",
  ORDER_CLIENT: "Ordreklient",
  ORDER_DATE: "Ordredato",
  ORDER_LEVEL: "Ordreniveau",
  ORDER_LINES: "Ordrelinjer",
  ORDER_NUMBER: "Ordrenummer",
  ORDER_OVERVIEW: "Ordreoversigt",
  ORDER_REFERENCE: "Ordre reference",
  ORDER_STATUS_10: "Foreløbig",
  ORDER_STATUS_20: "Bestilt",
  ORDER_STATUS_30: "Behandlet",
  ORDER_STATUS_80: "Under behandling",
  ORDER_STATUS_90: "Afsluttet",
  ORDER_STATUS_99: "Annulleret",
  ORDER_TIME: "Ordretid",
  ORDER_TYPE: "Ordretype",
  OUTBOUND: "Udgående",
  OUTBOUND_CREATE_NEW_ORDER: "Opret ny udgående ordre",
  OUTBOUND_TRACK_AND_TRACE: "Udgående",
  PACKAGE: "Pakke",
  PACKAGE_LEVEL: "Pakkeniveau",
  PACKAGE_NUMBER: "Pakkenummer",
  PACKAGE_OVERVIEW: "Pakkeoversigt",
  PACKAGES: "Pakker",
  PAGE_NOT_FOUND_DESCRIPTION: "Siden kunne ikke findes, kontakt support eller administrator ved gentagne problemer",
  PAGE_NOT_FOUND: "Siden kunne ikke findes!",
  PASSWORD: "Adgangskode",
  PASSWORD_CHANGED: "Din adgangskode er blevet ændret, log venligst ind igen.",
  PASSWORDS_DOESNT_MATCH: "Adgangskode og gentag adgangskode stemmer ikke overens",
  PASSWORD_EXPIRED: "Adgangskode udløbet",
  PASSWORD_HAS_TO_HAVE: "Adgangskode skal have:",
  PASSWORD_MISSING: "Adgangskode mangler",
  PASSWORD_POLICIES: "Adgangskode politikker",
  PASSWORD_REPEAT_MISSING: "Gentag adgangskode mangler",
  PATCH_LEVEL: "Patch niveau",
  PERSONAL_DATA: "Persondata",
  PHONE_NO: "Telefon nr.",
  PHONE_NUMBER_MISSING: "Telefonnummer mangler",
  PHONE_SHORT: "Tlf",
  PLANNING_LIST: "Planlægningsliste",
  PLANNING_LIST_EXTERNAL: "Planlægningsliste ekstern",
  PREVIOUS_PASSWORD_MISSING: "Tidligere adgangskode mangler",
  PRODUCT_GROUP: "Produktgruppe",
  PROFILE_INFORMATION_UPDATED: "Dine oplysninger er blevet opdateret",
  PURCHASE_ORDER: "Indkøbsordre",
  PURCHASE_ORDER_CREATE: "Opret ordre",
  PURCHASE_ORDER_IMPORT: "Indkøbsordre - import",
  PURCHASE_ORDER_IMPORT_FILE_DESCRIPTION: "Her kan du importere en indkøbsordre ved at vælge en fil til import.",
  PURCHASE_ORDER_LINES: "Indkøbsordre linjer",
  PURCHASE_ORDER_OVERVIEW: "Indkøbsordre oversigt",
  PURCHASE_ORDER_REQUEST: "Indkøbsordre anmodning",
  PURCHASE_ORDER_REGISTER_NEW: "Indkøbsordre - registrer ny",
  PURCHASE_ORDER_QUESTION: "Indkøbsordrer",
  PURCHASE_ORDERS_PRELIMINARY: "Indkøbsordrer - foreløbige",
  QLIK_SENSE: "Qlik Sense",
  QUALITY_REPORTS: "Kvalitetsrapporter",
  QUARANTINE_WAREHOUSE: "Karantænelager",
  QLIK_SENSE_USER: "Qlik Sense bruger",
  QUICK_ACCESS: "Hurtig adgang",
  QUANTITY_FROM_BATCH: "Mængde fra batch",
  READ_MORE: "Læs mere",
  REALM: "Rige",
  RECIPIENT: "Modtager",
  RECIPIENT_ADRESS: "Modtager adresse",
  RECIPIENT_DETAIL: "Modtager detaljer",
  RECIPIENT_EMAIL: "Modtager e-mail",
  RECIPIENT_NAME: "Modtager navn",
  RECIPIENT_TELEPHONE_NUMBER: "Modtager telefonnummer",
  RECLAMATIONS: "Reklamationer",
  RECEIVER: "Modtager",
  REDIRECT_USER: "Bruger - Omdiriger",
  REFERENCE_ORDER: "Referenceordre",
  REGION: "Region",
  REGIONS: "Regioner",
  RELOAD: "Genindlæs",
  REPEAT_PASSWORD: "Gentag adgangskode",
  RESET_PASSWORD_TITLE: "Set your new password",
  REPORT_ERROR_TITLE: "Rapporter fejl",
  REPORTS: "Rapporter",
  RESET_PASSWORD: "Nulstil adgangskode",
  RESET: "Nulstil",
  RESET_LINK_IS_SENT: "Et link til nulstilling af din adgangskode er sendt til din e-mail",
  RESERVATIONS_LIST: "Reservationsliste",
  RETURN_CARRIER_SERVICES: "Retur / Transportørtjenester",
  RETURN_HANDLING_LOAD_CARRIER: "Returhåndtering - last transportør",
  RETURN_MAIN_INFO_GOODS_OWNER: "Retur / Indstillinger pr. vareejer",
  RETURN_PACKAGE_TYPES: "Retur / Pakningstyper",
  RETURN_PRINTERS: "Retur / Printere",
  RETURN_RECEIVER_ADDRESS: "Retur / Modtageradresser",
  RETURN_STATUS: "Retur / Skabelon Returstatus",
  RETURN_TYPES: "Retur / Skabeloner typer af returnerbare",
  RETURN_WAREHOUSE: "Returlager",
  RESERVED: "Reserveret",
  RESERVED_QUANTITY: "Reserveret mængde",
  REQUESTED_DELIVERY_DATE: "Ønsket leveringsdato",
  ROWS: "Rækker",
  SAVE: "Gem",
  SAVE_NEW_PASSWORD: "Gem ny adgangskode",
  SAVE_VIEW: "GEM VISNING",
  SEARCH: "Søg",
  SEARCH_FOR_CASE: "Søg efter sag",
  SEARCH_FOR_PURCHASE_ORDER_NBR_OR_ITEM_NBR: "Søg efter indkøbsordrenummer eller varenummer",
  SEND: "Send",
  SERIAL_NUMBER: "Serienummer",
  SERVER_ERROR: "Serverfejl",
  SERVER_ERROR_DESCRIPTION: "Serveren kunne ikke nås - kontakt support, hvis problemet fortsætter",
  SERVER_ERROR_UNEXPECTED_DESCRIPTION:
    "Der opstod en uventet fejl. Prøv igen og kontakt support, hvis problemet fortsætter",
  SERVER_ERROR_DESCRIPTION_REQUIRE_LOGIN: "Serveren kunne ikke nås - log venligst ind igen",
  SERVER_COULDNT_PROCESS: "Serveren kunne ikke returnere nogen data, kontakt support og beskriv, hvor fejlen opstod",
  SERVICE_TYPE: "Tjenestetype",
  SERVICE_TYPE_NOT_FOUND: "Ingen tjenestetype blev fundet",
  SERVICES_TITLE: "Ordre",
  SESSION_EXPIRED: "Din session er udløbet. Du skal logge ind igen for at fortsætte med at bruge applikationen",
  SETTINGS: "Indstillinger",
  SETTINGS_OTS_LIB: "Indstillinger (OTS Lib)",
  SHOW_EVENTS: "Vis begivenheder",
  SHOW_MORE: "Vis mere",
  SHOW_MORE_DETAILS: "Vis flere detaljer",
  SHOW_ONLY_ADJUSTMENT_TRANSACTIONS: "Vis kun justeringstransaktioner",
  SHOW_ONLY_ITEMS_WITH_BALANCE: "Vis kun varer med balance",
  SHOW_PAGE_SIZE: "Vis varer pr. side",
  SHOW_RESULT_ON: "Vis resultat på",
  SHIPMENT: "Forsendelse",
  SHIPMENT_LEVEL: "Forsendelsesniveau",
  SHIPMENT_NUMBER: "Forsendelsesnummer",
  SHIPMENT_OVERVIEW: "Forsendelsesoversigt",
  SITE_TITLE: "TPL KT 2023",
  SMS: "SMS",
  SMS_TEMPLATES: "SMS-skabeloner",
  STADIUM_PRICE_LABELING: "Prismærkning - Stadium",
  STATISTICS: "Statistik",
  STATUS: "Status",
  STATUS_COMMENT: "Statuskommentar",
  STATUS_CODE: "Statuskode",
  STATUS_CODE_COLOR: "Begivenhedsfarver",
  STATUS_TRANSLATION: "Statusoversættelser",
  SYSTEM_ADMINISTRATION: "Systemadministration",
  SYSTEM_CONTROL: "Systemkontrol",
  SYSTEM_STATISTICS: "Statistik",
  SYSTEM_TOOLS: "Systemværktøjer",
  SUB_NAV_MY_ACCOUNT: "Min konto",
  SUB_NAV_MY_CASES: "Mine sager",
  SUB_NAV_MY_CASES_INFO: "Detaljer",
  SUB_NAV_MY_CASES_FORM_DATA: "Formular data",
  SUB_NAV_MY_CASES_ATTACHMENTS: "Vedhæftninger",
  SUB_NAV_MY_PERSONAL_DATA: "Persondata",
  SUB_NAV_MY_PASSWORD: "Adgangskode",
  SUPPLIER: "Leverandør",
  SUPPLEMENT: "Tillæg",
  SUPPORT: "Support",
  SURNAME: "Efternavn",
  SURNAME_MISSING: "Efternavn mangler",
  TIME: "Tid",
  TO_DATE: "Til dato",
  TO_DELIVERY_DATE: "Til leveringsdato",
  TO_ORDER_DATE: "Til ordredato",
  TOKEN_MISSING: "Token mangler",
  TRACK_AND_TRACE: "Track & Trace",
  TRACK_AND_TRACE_DESCRIPTION: "Spor dine kundeordrer",
  TRACK_AND_TRACE_PAGE_SEARCH: "Søg efter ordrenummer, forsendelsesnummer eller pakkenummer",
  TRACK_AND_TRACE_STATISTICS: "Track & Trace / Statistik",
  TRACK_PACKAGE_WITH_CARRIER: "Spor pakke med transportør",
  TRANSACTION: "Transaktion",
  TRANSACTION_CODE: "Transaktionskode",
  TRANSACTIONS: "Transaktioner",
  TRUE: "Ja",
  TRYING_TO_RECONNECT: "Forsøger at oprette forbindelse til serveren...",
  TYPE: "Type",
  UNABLE_TO_LOG_OUT: "Desværre kunne vi ikke logge dig ud, prøv igen",
  UNIT: "Enhed",
  UNLOADING_REPORTS_SHOW: "Aflæsningsrapporter - vis",
  UPLOAD_DOCUMENT: "Vedhæft dokument",
  UPLOAD_SUCCESSFUL: "Upload vellykket",
  USER: "Bruger",
  USER_ACCESS_MESSAGES: "Brugeradgangsbeskeder",
  USER_ACCESS_MESSAGES_EXCEPTIONS: "Brugeradgangsbeskeder - Undtagelser",
  USER_ACCESS_MESSAGES_READ_LOG: "Brugeradgangsbeskeder - Læselog",
  USER_IS_NOT_LOGGED_IN: "Bruger er ikke logget ind",
  USER_MESSAGE_NOT_ACCEPTED: "Vilkårene skal accepteres for at bruge denne tjeneste",
  USER_MESSAGES: "Brugerbeskeder",
  USERNAME: "Brugernavn",
  USERNAME_MISSING: "Brugernavn mangler",
  USER_GROUPS: "Brugergrupper",
  USER_LOGIN_HISTORY: "Bruger login historik",
  USER_ROLES: "Brugerroller",
  USER_SESSIONS: "Brugersessioner",
  USER_TASK_LOG: "Brugeropgavelog",
  USERS: "Brugere",
  USERS_CONTROL_TOWER: "Brugere Control Tower",
  USERS_GROUPS_AND_ROLES: "Brugere, grupper og roller",
  VERSION: "Version",
  VIEW_PACKAGE: "Vis pakke",
  VIRTUAL_SESSIONS: "Virtuelle sessioner",
  VOLUME: "Volumen",
  VOLUME_STATISTICS: "Volumenstatistik",
  WAREHOUSE: "Lager",
  WAREHOUSE_TRANSACTIONS: "Lagertransaktioner",
  WEB_AND_REST_SERVICES: "Web & REST-tjenester",
  WEIGHT: "Vægt",
  WELCOME_TEXT: "",
  WELCOME_TITLE: "Velkommen til Control Tower",
  WEEK_LETTER_SUPPORT: "Administrer notifikationer",
  WIDTH: "Bredde",
  WORK_WITH_PROFORMA: "Arbejd med proforma",
  WRONG_USERNAME_OR_PASSWORD: "Forkert brugernavn eller adgangskode",
  YES: "Ja",
  YOUR_CASES: "dine sager",
  YOUR_LINK_OPENED_IN_NEW_TAB: "Dit link er åbnet i en ny fane i browseren",
  ZETES_IMAGE_LINK: "Zetes-link",
  ZIP_CODE: "Postnummer",
};

export default da;
