import { createSelector } from "@reduxjs/toolkit";
import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { Item, itemSliceName } from "./dataTypes";
import { itemAdapter } from "./itemAdapter";

const selectSliceState = (state: RootState) => state[appDataName][itemSliceName];

const adapterSelectors = itemAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectItemEntities = adapterSelectors.selectEntities;
export const selectItems = adapterSelectors.selectAll;
export const makeSelectItem = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);

export function makeSelectItemsById(ids: string[] = []) {
  return createSelector(selectItemEntities, entities => {
    return ids.reduce<Item[]>((items, id) => {
      const item = entities[id];

      return item ? [...items, item] : items;
    }, []);
  });
}
