import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { fileSliceName } from "./dataTypes";
import { fileAdapter } from "./fileAdapter";
import { addAddFilesToItemReducers } from "./reducers/addFilesToItem";
import { addDeleteFileForItemReducers } from "./reducers/deleteFileForItem";
import { addFetchFilesForItemReducers } from "./reducers/fetchFilesForItem";
import { addGetCustomerOrderFilesUploadedToWMSReducers } from "./reducers/getCustomerOrderFilesUploadedToWMS";
import { addGetPurchaseOrderFilesUploadedToWMSReducers } from "./reducers/getPurchaseOrderFilesUploadedToWMS";
import { addUploadCustomerOrderFilesToWMSReducers } from "./reducers/uploadCustomerOrderFilesToWMS";
import { addUploadPurchaseOrderFilesToWMSReducers } from "./reducers/uploadPurchaseOrderFilesToWMS";

const slice = createSlice({
  name: fileSliceName,
  initialState: fileAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addDeleteFileForItemReducers(builder);
    addFetchFilesForItemReducers(builder);
    addAddFilesToItemReducers(builder);
    addGetCustomerOrderFilesUploadedToWMSReducers(builder);
    addGetPurchaseOrderFilesUploadedToWMSReducers(builder);
    addUploadCustomerOrderFilesToWMSReducers(builder);
    addUploadPurchaseOrderFilesToWMSReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
