import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { fileSliceName } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { Item } from "../dataTypes";
import { itemAdapter } from "../itemAdapter";

async function doDeleteFile(dispatch: AppDispatch, { itemId, fileId }: { itemId: string; fileId: string }) {
  const request = new Request(api.item.deleteFile(itemId, fileId), Method.POST);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const deleteFile = createAsyncThunk<void, { itemId: string; fileId: string }>(
  fileSliceName + "/deleteFile",
  doDeleteFile,
);

export const addDeleteFileReducers = (builder: EntityActionReducerBuilderInterface<Item>) => {
  builder.addCase(deleteFile.fulfilled, (state, action) => {
    const itemId = action.meta.arg.itemId;
    const item = state.entities[itemId];

    if (item?.files == null) return;

    const files = item.files;

    itemAdapter.updateOne(state, {
      id: itemId,
      changes: {
        files: { ...files, count: files.count - 1, ids: files.ids.filter(id => id !== action.meta.arg.fileId) },
      },
    });
  });
};
