import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { Item, ResponseItem } from "../dataTypes";

const mapFulfilledItemResponse = ({
  responseItem,
  currentItem,
}: {
  responseItem: ResponseItem | undefined;
  currentItem: Item | undefined;
}) => {
  const item: Item = mapFulfilledResponseObject(responseItem);

  item.files = currentItem?.files;

  return item;
};

export default mapFulfilledItemResponse;
