import { Box } from "@mui/material";
import { GridFilterModel, GridPaginationModel, GridSortModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { useAppSelector } from "../../../app/hooks";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { GetItemsFilter, Item, ITEM_FIELDS } from "../../../features/item/dataTypes";
import useGetItems from "../../../features/item/hooks/useGetItems";
import { selectCurrentGoodsOwnerId } from "../../../features/session/sessionSelectors";
import { BINDER } from "../../../features/utils/SqlBinders";
import { FILTER_OPERATOR, ORDER_BY_OPERATOR } from "../../../features/utils/SqlOperators";
import useTranslate from "../../../language/useTranslate";
import { DEFAULT_SMALL_PAGINATION_MODEL } from "../../DataGrid/config/DataGridConfig";
import DataGrid from "../../DataGrid/DataGrid";
import {
  FILTER_MODEL_ACTION as ACTION,
  getOrderByModel,
  getPaginatedRequestFilter,
  getWhereFilterByModel,
  filterModelReducer as reducer,
} from "../../DataGrid/util/DataGridUtil";
import PromptWrapper from "../../shared/prompt/PromptWrapper";
import useItemPromptColumns from "./useItemPromptColumns";

const ItemDataGrid = ({ onSelect }: { onSelect: (item: Item) => void }) => {
  const ITEM_COLUMNS = useItemPromptColumns();

  const apiRef = useGridApiRef();

  const [{ orderByModel, paginationModel, whereFilterModel, visualFilterModel }, dispatch] = useImmerReducer(reducer, {
    whereFilterModel: { items: [] },
    visualFilterModel: { items: [] },
    orderByModel: [],
    paginationModel: DEFAULT_SMALL_PAGINATION_MODEL,
  });

  const currentGoodsOwnerId = useAppSelector(selectCurrentGoodsOwnerId);

  const filter = useMemo<GetItemsFilter | undefined>(() => {
    const whereGroups = getWhereFilterByModel(whereFilterModel);

    const paginatedRequestFilter = getPaginatedRequestFilter(
      paginationModel,
      getOrderByModel(orderByModel),
      whereGroups,
    );

    if (paginatedRequestFilter.orderByFields?.length === 0) {
      paginatedRequestFilter.orderByFields = [
        { field: ITEM_FIELDS.CLIENT_ITEM_NUMBER, operator: ORDER_BY_OPERATOR.ASC },
      ];
    }

    paginatedRequestFilter.whereGroups = [
      {
        binder: BINDER.AND,
        fields: [
          {
            field: ITEM_FIELDS.GOODS_OWNER_ID,
            operator: FILTER_OPERATOR.EQ,
            value: currentGoodsOwnerId ?? null,
          },
          ...paginatedRequestFilter.whereGroups.flatMap(whereGroup => whereGroup.fields),
        ],
      },
    ];

    return paginatedRequestFilter;
  }, [whereFilterModel, paginationModel, orderByModel, currentGoodsOwnerId]);

  const { items, count, isLoading } = useGetItems({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  const onFilterChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      dispatch({
        type: ACTION.CHANGE_WHERE_FILTER_MODEL,
        whereFilterModel: gridFilterModel,
        columns: ITEM_COLUMNS,
      });
    },
    [dispatch, ITEM_COLUMNS],
  );

  const onSortModelChange = useCallback(
    (gridSortModel: GridSortModel) => {
      dispatch({ type: ACTION.CHANGE_ORDER_BY_MODEL, orderByModel: gridSortModel });
    },
    [dispatch],
  );

  const changePaginationModel = useCallback(
    (changedPaginationModel: GridPaginationModel) => {
      dispatch({ type: ACTION.CHANGE_PAGINATION_MODEL, paginationModel: changedPaginationModel });
    },
    [dispatch],
  );

  return (
    <Box height={"auto"}>
      <DataGrid
        apiRef={apiRef}
        autoHeight={true}
        loading={isLoading}
        unstable_headerFilters
        pagination
        paginationMode={"server"}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        onPaginationModelChange={changePaginationModel}
        rows={items}
        rowCount={count}
        columns={ITEM_COLUMNS}
        filterMode={"server"}
        filterModel={visualFilterModel}
        onFilterModelChange={onFilterChange}
        sortingMode={"server"}
        sortModel={orderByModel}
        onSortModelChange={onSortModelChange}
        onRowSelectionModelChange={newRowSelectionModel => {
          const selectedItem = items.find(item => item.id === newRowSelectionModel[0]);

          if (selectedItem != null) onSelect(selectedItem);
        }}
      />
    </Box>
  );
};

const ItemPrompt = ({ onSelect, closePrompt }: { onSelect: (item: Item) => void; closePrompt: () => void }) => {
  const translate = useTranslate();

  return (
    <PromptWrapper
      onSelectRow={onSelect}
      title={translate("ITEMS")}
      PromptBody={ItemDataGrid}
      closePrompt={closePrompt}
    />
  );
};

export default ItemPrompt;
