export const CLIENT_SELECTED = "client_selected";
export const COMFLOW_SESSION_ID = "ComflowSessionId";
export const CURRENT_GOODS_OWNER_ID = "currentGoodsOwnerId";
export const LOGGED_IN = "loggedIn";
export const LOGIN_TOKEN = "loginToken";
export const REMEMBER_ME = "rememberMe";
export const SESSION_ID = "sessionId";
export const SINGLE_SIGN_ON_RESPONSE = "singleSignOnResponse";
export const ITEM_DETAIL_CUSTOMER_ORDERS_GRID_ID = "ITEM_DETAIL_CUSTOMER_ORDERS_GRID_ID";
export const ITEM_DETAIL_ITEM_BATCHES_GRID_ID = "ITEM_DETAIL_ITEM_BATCHES_GRID_ID";
export const ITEM_DETAIL_PURCHASE_ORDER_GRID_ID = "ITEM_DETAIL_PURCHASE_ORDER_GRID_ID";
export const ITEM_DETAIL_WAREHOUSE_TRANSACTIONS_GRID_ID = "ITEM_DETAIL_WAREHOUSE_TRANSACTIONS_GRID_ID";
export const ITEM_GRID_ID = "ITEMS_GRID_ID";
export const PURCHASE_ORDER_GRID_ID = "PURCHASE_ORDER_GRID_ID";
export const PURCHASE_ORDER_LINES_IN_DETAIL_GRID_ID = "PURCHASE_ORDER_LINES_IN_DETAIL_GRID_ID";
export const TRACK_AND_TRACE_ORDERS_GRID_ID = "TRACK_AND_TRACE_CUSTOMER_ORDERS";
export const TRACK_AND_TRACE_PACKAGES_GRID_ID = "TRACK_AND_TRACE_CUSTOMER_ORDERS_PACKAGES";
export const TRACK_AND_TRACE_SHIPMENTS_GRID_ID = "TRACK_AND_TRACE_SHIPMENTS";
export const WAREHOUSE_TRANSACTIONS_GRID_ID = "WAREHOUSE_TRANSACTIONS_GRID_ID";

export const LOCAL_STORAGE_KEYS_DO_NOT_CLEAR_ON_LOGOUT = [
  ITEM_DETAIL_CUSTOMER_ORDERS_GRID_ID,
  ITEM_DETAIL_ITEM_BATCHES_GRID_ID,
  ITEM_DETAIL_PURCHASE_ORDER_GRID_ID,
  ITEM_DETAIL_WAREHOUSE_TRANSACTIONS_GRID_ID,
  ITEM_GRID_ID,
  PURCHASE_ORDER_GRID_ID,
  PURCHASE_ORDER_LINES_IN_DETAIL_GRID_ID,
  TRACK_AND_TRACE_ORDERS_GRID_ID,
  TRACK_AND_TRACE_PACKAGES_GRID_ID,
  TRACK_AND_TRACE_SHIPMENTS_GRID_ID,
  WAREHOUSE_TRANSACTIONS_GRID_ID,
];
